import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Grid, Typography, Box, styled, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import CardItem from "../CardItem/CardItem";
import { AuctionItemService } from "../../services/AuctionItemService";

export default function NearestItemsSlider() {
  const { t } = useTranslation();
  const sliderRef = useRef();
  const navigate = useNavigate();
  const [auctionItems, setAuctionItems] = useState([]);

  useEffect(() => {
    const fetchAuctionItems = async () => {
      try {
        const items = await AuctionItemService.fetchAuctionItems();
        const inactiveItems = items.filter((item) => item.status === "inactive");

        inactiveItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

        setAuctionItems(inactiveItems);
      } catch (error) {
        console.error("Error fetching auction items:", error);
      }
    };

    fetchAuctionItems();
  }, []);

  const StyledIconButton = styled(IconButton)({
    "&::before, &::after": {
      display: "none",
    },
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const navigateToLots = () => {
    navigate("/lots");
  };

  return (
    <Box sx={{ my: 7 }}>
      <Grid sx={{ mb: 4 }} item container justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontSize: "36px", fontWeight: 500 }} variant="h4">
          {t("nearest.title")}
        </Typography>
        <Button
          sx={{
            padding: "14px 32px",
            color: "#3083FF",
            border: "1px solid #3083FF",
            borderRadius: 2,
          }}
          variant="outlined"
          onClick={navigateToLots}>
          {t("hurryUp.allLots")}
        </Button>
      </Grid>
      <Box style={{ position: "relative" }}>
        <Slider ref={sliderRef} {...settings}>
          {auctionItems.map((item) => (
            <Box key={item._id}>
              <Grid item xs={12} lg={3} sm={6} px={0.75}>
                <CardItem item={item} type="nearest" />
              </Grid>
            </Box>
          ))}
        </Slider>
        <StyledIconButton
          className="slick-prev"
          sx={{
            position: "absolute",
            left: "-20px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            width: 40,
            height: 40,
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            },
            "&:focus": {
              color: "black",
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            },
          }}
          onClick={prevSlide}>
          <ArrowBackIosNewIcon />
        </StyledIconButton>
        <StyledIconButton
          className="slick-next"
          sx={{
            position: "absolute",
            right: "-20px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            width: 40,
            height: 40,
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            },
            "&:focus": {
              color: "black",
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            },
          }}
          aria-label="next"
          onClick={nextSlide}>
          <ArrowForwardIosIcon />
        </StyledIconButton>
      </Box>
    </Box>
  );
}
