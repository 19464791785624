import React, { useState, useEffect } from "react";
import { Box, Grid, CardMedia } from "@mui/material";
import { AuctionItemService } from "../../services/AuctionItemService";

export default function ProductSlider({ auctionId }) {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const loadAuctionItem = async () => {
      try {
        const auctionItem = await AuctionItemService.fetchAuctionItemById(auctionId);
        const fetchedImages = [];

        if (auctionItem.image) {
          fetchedImages.push({ src: auctionItem.image, alt: auctionItem.name });
        }

        if (auctionItem.galleryImages && auctionItem.galleryImages.length > 0) {
          const galleryImages = auctionItem.galleryImages.map((image, index) => ({
            src: image,
            alt: `Gallery Image ${index}`,
          }));
          fetchedImages.push(...galleryImages);
        }

        setImages(fetchedImages);
        if (fetchedImages.length > 0) {
          setSelectedImage(fetchedImages[0]);
          console.log("Good fetching auction item:");
        }
      } catch (error) {
        console.error("Error fetching auction item:", error);
      }
    };

    loadAuctionItem();
  }, [auctionId]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  if (!images || images.length === 0 || !selectedImage) {
    return <p>Loading...</p>;
  }

  return (
    <Grid item xs={12} lg={4}>
      <CardMedia
        component="img"
        image={`${process.env.REACT_APP_BACKEND_BASE_URL}${selectedImage.src}`}
        alt={selectedImage.alt}
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: 2,
          mb: 2,
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {images.map((image, index) => (
          <Box key={index} sx={{ mx: 1 }}>
            <CardMedia
              component="img"
              image={`${process.env.REACT_APP_BACKEND_BASE_URL}${image.src}`}
              alt={image.alt}
              onClick={() => handleImageClick(image)}
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "auto",
                maxWidth: "100px",
                borderRadius: 2,
                boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
                "&:hover": {
                  border: "1px solid #3083FF",
                },
              }}
            />
          </Box>
        ))}
      </Box>
    </Grid>
  );
}
