import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { fetchCategories } from "../../services/CategoryService";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const CategoriesAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useState({
    id: "",
    name: "",
    slug: "",
    image: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const handleRowClick = (category) => {
    navigate(`/admin/categories/${category._id}`);
  };

  const handleSearchChange = (event) => {
    setSearchParams({
      ...searchParams,
      [event.target.name]: event.target.value,
    });
  };

  // Логика фильтрации категорий
  const filteredCategories = categories.filter((category) => {
    return (
      (!searchParams.id || category._id.toLowerCase().includes(searchParams.id.toLowerCase())) &&
      (!searchParams.name ||
        category.name.toLowerCase().includes(searchParams.name.toLowerCase())) &&
      (!searchParams.slug ||
        category.slug.toLowerCase().includes(searchParams.slug.toLowerCase())) &&
      (!searchParams.image ||
        (category.image && category.image.toLowerCase().includes(searchParams.image.toLowerCase())))
    );
  });

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCategories = filteredCategories.slice(startIndex, endIndex);

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
          Категории
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/admin/add-category")}
          sx={{
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "10px",
            p: "14px 34px",
            fontSize: "14px",
            fontWeight: 400,
            textTransform: "none",
            mb: 4,
          }}>
          Добавить категорию
        </Button>
      </Box>

      <Table sx={{ minWidth: 650, mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                p: 0,
                borderBottom: "1px solid #ABBED1",
                width: 40,
                textAlign: "start",
              }}>
              <Checkbox sx={{ pt: 4 }} />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0.5,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 100,
              }}>
              ID
              <TextField
                name="id"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.id}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0.5,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 240,
              }}>
              Категория
              <TextField
                name="name"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.name}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0.5,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 240,
              }}>
              Slug
              <TextField
                name="slug"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.slug}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0.5,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 240,
              }}>
              Изображение
              <TextField
                name="image"
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                value={searchParams.image}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedCategories.map((category, index) => (
            <TableRow
              key={category._id}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}
              onClick={() => handleRowClick(category)}
              style={{ cursor: "pointer" }}>
              <TableCell sx={{ borderBottom: "none", p: 0 }}>
                <Checkbox />
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{category._id}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{category.name}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{category.slug}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(filteredCategories.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

export default CategoriesAdmin;
