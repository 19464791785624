import React from "react";
import Main from "./pages/Main/Main";
import Lots from "./pages/Lots/Lots";
import Product from "./pages/Product/Product";
import Terms from "./pages/Terms/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import OfferTerms from "./pages/OfferTerms/OfferTerms";
import PaymentDelivery from "./pages/PaymentDelivery/PaymentDelivery";
import Contacts from "./pages/Contacts/Contacts";
import Login from "./pages/Login/Login";
import PasswordRecovery from "./pages/PasswordRecovery/PasswordRecovery";
import Register from "./pages/Register/Register";
import PersonalInfo from "./pages/Register/PersonalInfo";
import Verification from "./pages/Register/Verification";
import Checkout from "./pages/Checkout/Checkout";
import ProfilePage from "./pages/Profile/ProfilePage";
import CategoriesAdmin from "./pages/AdminPanel/CategoriesAdmin";
import LotsAdmin from "./pages/AdminPanel/LotsAdmin";
import AddNewCategory from "./pages/AdminPanel/AddNewCategory";
import AddNewLot from "./pages/AdminPanel/AddNewLot";
import EditLot from "./pages/AdminPanel/EditLot";
import OrdersAdmin from "./pages/AdminPanel/OrdersAdmin";
import OrderDetail from "./pages/AdminPanel/OrderDetail";
import MainAdmin from "./pages/AdminPanel/MainAdmin";
import PersonalData from "./pages/Profile/PersonalData";
import MyShopping from "./pages/Profile/MyShopping";
import Favorites from "./pages/Profile/Favorites";
import Balance from "./pages/Profile/Balance";
import MyAuctions from "./pages/Profile/MyAuctions";
import ReferralProgram from "./pages/Profile/ReferralProgram";
import AppLayout from "./App.layout";
import AdminLayout from "./Admin.layout";
import ProtectedRoute from "./ProtectedRoute";
import EditCategory from "./pages/AdminPanel/EditCategory";
import UsersAdmin from "./pages/AdminPanel/UsersAdmin";
import UserDetail from "./pages/AdminPanel/UserDetail";

const RouterBuilder = () => {
  const generalRoutes = [
    { path: "/", element: <Main /> },
    { path: "/lots", element: <Lots /> },
    { path: "/lots/:auctionId", element: <Product /> },
    { path: "/terms", element: <Terms /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/offer-terms", element: <OfferTerms /> },
    { path: "/payment-and-delivery", element: <PaymentDelivery /> },
    { path: "/contacts", element: <Contacts /> },
    { path: "/login", element: <Login /> },
    { path: "/password-recovery", element: <PasswordRecovery /> },
    { path: "/register", element: <Register /> },
    { path: "/register/personal-info", element: <PersonalInfo /> },
    { path: "/register/verification", element: <Verification /> },
    { path: "/checkout", element: <Checkout /> },
  ];

  const profileRoutes = [
    { path: "personal-data", element: <PersonalData /> },
    { path: "my-purchases", element: <MyShopping /> },
    { path: "favorites", element: <Favorites /> },
    { path: "balance", element: <Balance /> },
    { path: "my-auctions", element: <MyAuctions /> },
    { path: "referral-program", element: <ReferralProgram /> },
  ];

  const adminRoutes = [
    { path: "main", element: <MainAdmin /> },
    { path: "categories", element: <CategoriesAdmin /> },
    { path: "categories/:id", element: <EditCategory /> },
    { path: "lots", element: <LotsAdmin /> },
    { path: "add-category", element: <AddNewCategory /> },
    { path: "add-lot", element: <AddNewLot /> },
    { path: "lots/:id", element: <EditLot /> },
    { path: "orders", element: <OrdersAdmin /> },
    { path: "order-detail", element: <OrderDetail /> },
    { path: "users", element: <UsersAdmin /> },
    { path: "users/:userId", element: <UserDetail /> },
  ];

  const routes = [
    {
      path: "/",
      element: <AppLayout />,
      children: [
        ...generalRoutes,
        {
          path: "profile",
          element: <ProfilePage />,
          children: profileRoutes,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <ProtectedRoute adminOnly={true}>
          <AdminLayout />
        </ProtectedRoute>
      ),
      children: adminRoutes,
    },
  ];

  return routes;
};

export default RouterBuilder;
