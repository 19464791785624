import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchCategoryById,
  updateCategory,
  deleteCategory,
  fetchCategories,
} from "../../services/CategoryService";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid #89939E",
    backgroundColor: "transparent",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [img, setImg] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchCategoryById(id);
        setName(categoryData.name);
        setSlug(categoryData.slug || "");
        setParentCategory(categoryData.parentCategory || "");
        setImg(categoryData.img || "");
        setDescription(categoryData.description || "");
      } catch (error) {
        console.error("Ошибка при получении категории:", error);
      }
    };

    const loadCategories = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);
      } catch (error) {
        console.error("Ошибка при загрузке категорий:", error);
      }
    };

    fetchCategory();
    loadCategories();
  }, [id]);

  const handleUpdate = async () => {
    const updatedCategory = {
      name,
      slug: slug || undefined,
      parentCategory: parentCategory || undefined,
      img: img || undefined,
      description: description || undefined,
    };

    try {
      await updateCategory(id, updatedCategory);
      navigate("/admin/categories");
    } catch (error) {
      console.error("Ошибка при обновлении категории:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCategory(id);
      navigate("/admin/categories");
    } catch (error) {
      console.error("Ошибка при удалении категории:", error);
    }
  };

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Paper sx={{ borderRadius: "10px", boxShadow: "none", p: 3 }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, my: 3 }}>
        Редактировать категорию
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Наименование *
            </Typography>
            <TextField
              placeholder="Наименование"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Slug</Typography>
            <TextField
              placeholder="Slug"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Родительская категория
            </Typography>
            <TextField
              select
              value={parentCategory}
              onChange={(e) => setParentCategory(e.target.value)}
              variant="outlined"
              sx={{ ...textFieldStyles, width: "100%" }}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) =>
                  categories.find((cat) => cat._id === value)?.name || "Родительская категория",
              }}>
              <MenuItem value="">
                <em>Нет</em>
              </MenuItem>
              {categories.map((cat) => (
                <MenuItem key={cat._id} value={cat._id}>
                  {cat.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Изображение (URL)
            </Typography>
            <TextField
              placeholder="URL изображения"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
              value={img}
              onChange={(e) => setImg(e.target.value)}
            />
          </Box>
        </Box>
        <Box sx={{ width: 360, display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            onClick={handleUpdate}
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "14px 34px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Сохранить изменения
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteDialogOpen}
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: 400,
            }}>
            Удалить категорию
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Подтверждение удаления</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы уверены, что хотите удалить эту категорию? Это действие нельзя отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EditCategory;
