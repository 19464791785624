import axiosInstance from "../axiosConfig";

export interface Category {
  _id: string;
  name: string;
  parentCategory?: string | null;
  img?: string;
  slug?: string;
  description?: string;
}

export const fetchCategories = async (): Promise<Category[]> => {
  try {
    const response = await axiosInstance.get<Category[]>("/api/categories");
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export const createCategory = async (categoryData: {
  name: string;
  slug?: string;
  parentCategory?: string;
  img?: string;
  description?: string;
}): Promise<Category> => {
  if (categoryData.parentCategory === "") {
    delete categoryData.parentCategory;
  }
  try {
    const response = await axiosInstance.post<Category>("/api/categories", categoryData);
    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

export const fetchCategoryById = async (id: string): Promise<Category> => {
  try {
    const response = await axiosInstance.get<Category>(`/api/categories/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching category by ID:", error);
    throw error;
  }
};

export const updateCategory = async (
  id: string,
  categoryData: Partial<Category>,
): Promise<Category> => {
  try {
    const response = await axiosInstance.patch<Category>(`/api/categories/${id}`, categoryData);
    return response.data;
  } catch (error) {
    console.error("Error updating category:", error);
    throw error;
  }
};

export const deleteCategory = async (id: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/api/categories/${id}`);
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
};
