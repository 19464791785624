import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";
import { WalletService } from "../../services/WalletService";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../Context/SocketContext";
import { UserService } from "../../services/UserService";
import FundsModal from "../FundsModal/FundsModal";

const Rate = ({ auction, userId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFundsModalOpen, setFundsModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [userLastBid, setUserLastBid] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);
  const [isWinner, setIsWinner] = useState(false);
  const [hasJoinedAuction, setHasJoinedAuction] = useState(false);
  const [auctionEndDate, setAuctionEndDate] = useState(new Date(auction.endDate));
  const [userBidCount, setUserBidCount] = useState(0);
  const [isSuperBidUnlocked, setIsSuperBidUnlocked] = useState(false);
  const [currentBid, setCurrentBid] = useState(auction.currentPrice);
  const socket = useSocket();

  const isCurrentBidEqualUserLastBid = userLastBid === currentBid && currentBid;
  //console.log("currentBidcurrentBidcurrentBidcurrentBid", currentBid);
  const log = (message, data) => {
    console.log(message, JSON.stringify(data, null, 2));
  };

  const handleOpenFundsModal = () => {
    setFundsModalOpen(true);
  };

  const handleCloseFundsModal = () => {
    setFundsModalOpen(false);
  };

  const calculateTimeLeft = (endDate) => {
    const now = new Date();
    const difference = endDate - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const fetchWalletBalance = async () => {
    try {
      const balance = await WalletService.getWalletBalance();
      setWalletBalance(balance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  const fetchUserLastBid = async () => {
    try {
      const lastBid = await AuctionItemService.fetchUserLastBid(auction._id);
      console.log('lastBid', lastBid)
      console.log('lastBid', lastBid.amount)
      if (lastBid && !lastBid.isParticipationFee) {
        setUserLastBid(lastBid.amount);
        setUserBidCount(lastBid.bidCount);
        setHasJoinedAuction(true);
      } else if (lastBid.isParticipationFee) {
        setHasJoinedAuction(true); //Участие есть, ставки нет
      }
    } catch (error) {
      console.error("Ошибка при получении последней ставки пользователя:", error);
    }
  };

  useEffect(() => {
    fetchUserLastBid();
  }, [auction]);

  const checkWinner = async () => {
    try {
      const auctionData = await AuctionItemService.fetchAuctionItemById(auction._id);
      const highestBid = auctionData.highestBid;
      console.log(auctionData)
      if (highestBid && highestBid.user && highestBid.user._id === userId) {
      auction.status = auctionData.status
      auction.isPaid = auctionData.isPaid
        setIsWinner(true);
      }
    } catch (error) {
      console.error("Error checking auction winner:", error);
    }
  };

  const joinAuction = async () => {
    if (!userId) {
      navigate("/login");
      return;
    }
    if (walletBalance < auction.participationFee) {
      handleOpenFundsModal();
      return;
    }

    try {
      const users = await UserService.fetchUsers();
      const userData = users.find((user) => user._id === userId);

      log("Joining auction with user:", userData);

      await WalletService.joinAuction(auction._id);
      setHasJoinedAuction(true);
      const updatedBalance = await WalletService.getWalletBalance();
      setWalletBalance(updatedBalance);

      socket.emit("user_joined", {
        auctionId: auction._id,
        user: {
          _id: userId,
          firstName: userData.firstName || "No Name",
          city: userData.city || "No City",
        },
      });

      log("Socket emit for joinAuction:", {
        auctionId: auction._id,
        user: {
          _id: userId,
          firstName: userData.firstName || "No Name",
          city: userData.city || "No City",
        },
      });
    } catch (error) {
      console.error("Error joining the auction:", error);
    }
  };

  const raiseBid = async (increment, isSuperBid) => {
    if (walletBalance < increment) {
      handleOpenFundsModal();
      return;
    }

    try {
      const users = await UserService.fetchUsers();
      const userData = users.find((user) => user._id === userId);

      const newBidAmount = auction.currentPrice + increment;

      await WalletService.deductParticipationFee(increment);

      const updatedBalance = await WalletService.getWalletBalance();
      setWalletBalance(updatedBalance);

      const bidResponse = await AuctionItemService.placeBid(auction._id, newBidAmount, isSuperBid);

      auction.currentPrice = newBidAmount;
      setUserLastBid(newBidAmount);
      setUserBidCount(bidResponse.bid.bidCount);

      socket.emit("new_bid", {
        auctionId: auction._id,
        bidders: [
          {
            user: {
              _id: userId,
              firstName: userData.firstName || "No Name",
              city: userData.city || "No City",
            },
            bidCount: bidResponse.bid.bidCount,
            lastBidAmount: newBidAmount,
          },
        ],
        currentPrice: newBidAmount,
      });

      log("Socket emit for raiseBid:", {
        auctionId: auction._id,
        bidders: [
          {
            user: {
              _id: userId,
              firstName: userData.firstName || "No Name",
              city: userData.city || "No City",
            },
            bidCount: bidResponse.bid.bidCount,
            lastBidAmount: newBidAmount,
          },
        ],
        currentPrice: newBidAmount,
      });

      if (bidResponse && bidResponse.updatedEndDate) {
        setAuctionEndDate(new Date(bidResponse.updatedEndDate));
      }
    } catch (error) {
      console.error("Error raising bid:", error);
    }
  };

  useEffect(() => {
    if (!auction) return;

    const timer = setInterval(async () => {
      const newTimeLeft = calculateTimeLeft(auctionEndDate);
      setTimeLeft(newTimeLeft);
      if (newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0 &&
        auction.status === "active") {
          console.log('kek', auction.status)
          const finishAuction = await AuctionItemService.updateAuctionItemStatus(auction._id, { status: "completed" })
          checkWinner();
          clearInterval(timer);
        } else if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0 &&
        auction.status === "completed"
      ) {
        checkWinner();
        clearInterval(timer);
      }
    }, 1000);

    fetchWalletBalance();
    fetchUserLastBid();
    return () => clearInterval(timer);
  }, [auction, userId, auctionEndDate]);

  useEffect(() => {
    if (socket) {
      socket.on("update_bidders", (updatedData) => {
        if (updatedData.auctionId === auction._id) {
          setCurrentBid(updatedData.currentPrice);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("update_bidders");
      }
    };
  }, [socket, auction._id]);

  useEffect(() => {
    if (userBidCount >= 10) {
      setIsSuperBidUnlocked(true);
    }
  }, [userBidCount]);

  const handleRaiseBid = () => raiseBid(auction.increment, false);

  const handleRaiseSuperBid = () => raiseBid(5000, true);

  const handleCheckout = () => {
    navigate("/checkout", {
      state: {
        auctionId: auction._id,
        auctionName: auction.name,
        finalPrice: auction.currentPrice,
      },
    });
  };

  const renderBox = (value, label) => (
    <Box
      mx={1}
      textAlign="center"
      sx={{
        pt: 0.25,
        background: "#343650",
        color: "#fff",
        borderRadius: 1,
        width: "64px",
        height: "64px",
        display: "flex",
        flexDirection: "column",
      }}>
      <Box
        sx={{
          fontSize: "36px",
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {String(value).padStart(2, "0")}
      </Box>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: 600,
          width: "100%",
          textAlign: "center",
        }}>
        {label}
      </Typography>
    </Box>
  );

  return (
    <Grid item xs={12} lg={5}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
        {auction.name}
      </Typography>
      <Box display={"flex"} alignItems={"center"} my={2}>
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: 400, mr: 1 }}>
          {t("rate.timeLeft")}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          {timeLeft && timeLeft.days != 0 && renderBox(timeLeft.days, "ДНЕЙ")}
          {timeLeft && renderBox(timeLeft.hours, t("rate.hours"))}
          {timeLeft && renderBox(timeLeft.minutes, t("rate.minutes"))}
          {timeLeft && renderBox(timeLeft.seconds, t("rate.seconds"))}
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mb={1}
        sx={{
          height: "32px",
          width: "100%",
          background: "rgba(246, 53, 53, 0.50)",
          borderRadius: "11px",
        }}>
        <Typography
          variant="body2"
          sx={{
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
          }}>
          {t("rate.currentBid")}
        </Typography>
        <Typography
          sx={{
            ml: 0.5,
            color: "black",
            fontSize: "14px",
            fontWeight: 700,
          }}>
          {currentBid} {t("rate.currency")}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mb={3}
        sx={{
          height: "32px",
          width: "100%",
          background: "rgba(0, 205, 82, 0.50)",
          borderRadius: "11px",
        }}>
        <Typography
          variant="body2"
          sx={{
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
          }}>
          {t("rate.lastBid")}
        </Typography>
        <Typography
          sx={{
            ml: 0.5,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
          }}>
          {userLastBid ? `${userLastBid} ${t("rate.currency")}` : "Нет ставок"}
        </Typography>
      </Box>
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            {t("rate.lotNumber")}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            {auction._id}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            {t("rate.startingPrice")}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            {auction.startingPrice} {t("rate.currency")}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            {t("rate.participationFee")}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            {auction.participationFee} {t("rate.currency")}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            {t("rate.minIncrement")}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            {auction.increment} {t("rate.currency")}
          </Typography>
        </Box>
      </Box>

      {auction.status === "completed" ? (
        isWinner && auction.isPaid === false ? (
          <Button
            sx={{
              mt: 3,
              color: "#fff",
              borderRadius: 3,
              fontSize: "16px",
              fontWeight: 400,
              width: "100%",
              textTransform: "none",
              py: 1,
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            }}
            onClick={handleCheckout}>
            Перейти к оформлению
          </Button>
        ) : isWinner && auction.isPaid === true ? (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", mt: 3, color: "green", fontWeight: 600 }}>
            Аукцион завершен. Заказ оплачен.
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", mt: 3, color: "red", fontWeight: 600 }}>
            Аукцион завершен. Вы не победитель.
          </Typography>
        )
      ) : (
        <>
          {!hasJoinedAuction ? ( // Проверяем, присоединился ли пользователь
            <Button
              onClick={joinAuction}
              sx={{
                mt: 3,
                color: "#fff",
                borderRadius: 3,
                fontSize: "16px",
                fontWeight: 400,
                width: "100%",
                textTransform: "none",
                py: 1,
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              }}>
              Участвовать
            </Button>
          ) : (
            <>
              <Button
                onClick={handleRaiseBid}
                sx={{
                  mt: 3,
                  color: "#fff",
                  borderRadius: 3,
                  fontSize: "16px",
                  fontWeight: 400,
                  width: "100%",
                  textTransform: "none",
                  py: 1,
                  background:
                    "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                }}
                disabled={Boolean(isCurrentBidEqualUserLastBid)}>
                Поднять на {auction.increment} {t("rate.currency")}
              </Button>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  display: "flex",
                  justifyContent: "center",
                  my: 1,
                }}>
                Сделайте 10 ставок, чтобы разблокировать
              </Typography>
              <Button
                onClick={handleRaiseSuperBid}
                sx={{
                  color: "#fff",
                  borderRadius: 3,
                  fontSize: "16px",
                  fontWeight: 400,
                  width: "100%",
                  textTransform: "none",
                  py: 1,
                  background:
                    "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                }}
                disabled={!isSuperBidUnlocked || isCurrentBidEqualUserLastBid}>
                Поднять на 5000 {t("rate.currency")}
              </Button>
            </>
          )}
        </>
      )}
      <FundsModal open={isFundsModalOpen} onClose={handleCloseFundsModal} />
    </Grid>
  );
};

export default Rate;
