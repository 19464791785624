import React, { useRef, useState, useEffect } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { fetchCategories } from "../../services/CategoryService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, []);

  const handleMouseDown = (e) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartX(e.clientX - containerRef.current.offsetLeft);
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !containerRef.current || startX === null) return;
    e.preventDefault();
    const x = e.clientX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/lots?category=${categoryId}`);
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: "200px",
        mt: { lg: 3, xs: 1 },
        pt: 1,
        pl: 1,
        display: "flex",
        overflowX: "auto",
        flexWrap: "nowrap",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}>
      {categories.map((category) => (
        <Grid key={category._id} item sx={{ mr: 1 }}>
          <Paper
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #3083FF",
              },
            }}
            onClick={() => handleCategoryClick(category._id)}>
            <Box>
              <Typography sx={{ fontWeight: 400, textAlign: "center" }} variant="subtitle1">
                {category.img && (
                  <img
                    src={`${process.env.REACT_APP_BACKEND_BASE_URL}${category.img}`}
                    alt={t(`categories.${category.name}`)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "20px",
                    }}
                  />
                )}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  textAlign: "center",
                  fontSize: "10px",
                  cursor: "default",
                  mb: -1,
                }}
                variant="caption"
                display="block"
                gutterBottom>
                {category.name}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Categories;
