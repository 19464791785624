import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button, Stepper, Step, StepLabel } from "@mui/material";
import * as faceapi from "face-api.js";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

const CustomStepIcon = (props) => {
  const { active, icon } = props;

  return (
    <Box
      sx={{
        position: "relative",
        width: 44,
        height: 44,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: active ? "linear-gradient(180deg, #3083FF 0%, #73ABFF 100%)" : "#f0f2f5",
        borderColor: active ? "none" : "#ABBED1",
        borderWidth: active ? "none" : "2px",
        borderStyle: "solid",
      }}>
      <Typography
        sx={{
          color: active ? "#fff" : "#ABBED1",
          fontSize: "20px",
          fontWeight: 600,
          position: "absolute",
        }}>
        {icon}
      </Typography>
    </Box>
  );
};

const Verification = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUser, register, verifyUser, completeRegistration } = useAuth();
  const [showImage, setShowImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [personalData, setPersonalData] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    const loadModels = async () => {
      console.log("Загрузка моделей face-api.js...");
      const MODEL_URL = "/models";
      try {
        await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
        await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
        await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
        await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
        setModelsLoaded(true);
        console.log("Модели успешно загружены");
      } catch (error) {
        console.error("Ошибка загрузки моделей:", error);
        setErrors({ general: "Не удалось загрузить модели для распознавания лиц." });
      }
    };

    loadModels();

    console.log("Инициализация данных из location.state или localStorage...");
    if (location.state) {
      console.log("Данные из location.state найдены:", location.state);
      setPersonalData(location.state);
      localStorage.setItem("personalInfoData", JSON.stringify(location.state));
    } else {
      const storedPersonalInfoData = localStorage.getItem("personalInfoData");
      const storedRegistrationData = localStorage.getItem("registrationData");

      if (storedPersonalInfoData) {
        console.log("Данные из localStorage (personalInfoData):", storedPersonalInfoData);
        setPersonalData(JSON.parse(storedPersonalInfoData));
      }

      if (storedRegistrationData) {
        setUser(JSON.parse(storedRegistrationData));
      }

      if (!storedPersonalInfoData && !storedRegistrationData) {
        console.error("Оба источника данных (registrationData и personalInfoData) отсутствуют");
        setErrors({ general: "Данные отсутствуют" });
      }
    }
  }, [location]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && modelsLoaded) {
      const imageUrl = URL.createObjectURL(file);
      setImageSrc(imageUrl);
      setDocumentFile(file);

      const img = await faceapi.bufferToImage(file);
      const detection = await faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions());

      if (detection.length > 0) {
        console.log("Лицо обнаружено", detection);
        setShowImage(true);
      } else {
        console.error("Лицо не обнаружено");
        setErrors({
          general: "Лицо не обнаружено на изображении. Пожалуйста, загрузите другое изображение.",
        });
        setShowImage(false);
      }
    } else {
      console.error("Модели не загружены или файл отсутствует");
      setErrors({ general: "Модели не загружены. Пожалуйста, подождите." });
    }
  };

  const handleNextStep = async () => {
    try {
      const registrationData = JSON.parse(localStorage.getItem("registrationData"));
      const personalInfoData = JSON.parse(localStorage.getItem("personalInfoData"));

      const formData = new FormData();

      if (registrationData) {
        Object.entries(registrationData).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      if (personalInfoData) {
        Object.entries(personalInfoData).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      if (documentFile) {
        formData.append("verificationDocument", documentFile);
      } else {
        setErrors({ general: "Пожалуйста, загрузите документ для верификации." });
        return;
      }

      if (registrationData) {
        await register(formData);
      } else if (personalInfoData) {
        await verifyUser(formData);
      }

      completeRegistration();
      navigate("/profile/personal-data");
    } catch (error) {
      setErrors({ general: "Ошибка регистрации и верификации" });
    }
  };

  const handleSkip = async () => {
    try {
      const registrationData = JSON.parse(localStorage.getItem("registrationData"));
      const personalInfoData = JSON.parse(localStorage.getItem("personalInfoData"));

      if (registrationData) {
        const formData = new FormData();

        Object.entries(registrationData).forEach(([key, value]) => {
          formData.append(key, value);
        });

        if (personalInfoData) {
          Object.entries(personalInfoData).forEach(([key, value]) => {
            formData.append(key, value);
          });
        }

        console.log("Регистрация пользователя...");
        await register(formData);
        completeRegistration();
        localStorage.removeItem("registrationData");
        localStorage.removeItem("personalInfoData");

        navigate("/profile/personal-data");
      } else {
        console.log("Регистрационные данные отсутствуют, перенаправляем в личный кабинет.");
        navigate("/profile/personal-data");
      }
    } catch (error) {
      console.error("Ошибка при обработке кнопки 'Позже':", error);
      setErrors({ general: "Не удалось завершить действие. Попробуйте снова." });
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "460px",
        margin: "0 auto",
        my: 5,
      }}>
      <Stepper
        activeStep={2}
        alternativeLabel
        sx={{
          width: "80%",
          "& .MuiStep-root": {
            p: 0,
          },
          "& .MuiStepLabel-root .MuiStepLabel-label": {
            display: "none",
          },
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 22px)",
            right: "calc(50% + 20px)",
          },
          "& .MuiStepConnector-line": {
            borderColor: "#ABBED1",
            borderTopWidth: "2px",
            top: "50%",
          },
        }}>
        {[1, 2, 3].map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
        Верификация
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: "14px", mb: 4, fontWeight: 400, textAlign: "center" }}>
        Загрузите ваше удостоверение личности или паспорт это обязательно для участия в аукционах
      </Typography>
      {showImage && (
        <Box sx={{ width: "100%", textAlign: "center", mb: 4 }}>
          <img src={imageSrc} alt="Uploaded document" style={{ maxWidth: "100%" }} />
        </Box>
      )}
      {errors.general && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errors.general}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: 3,
          flexDirection: showImage ? "column" : "row",
        }}>
        <Button
          onClick={handleSkip}
          variant={showImage ? "outlined" : "contained"}
          sx={{
            width: "100%",
            borderColor: "#3083FF",
            color: showImage ? "#3083FF" : "#fff",
            background: showImage
              ? "none"
              : "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            py: "14px",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            borderRadius: "10px",
            boxShadow: "none",
          }}>
          Позже
        </Button>
        <Button
          variant="contained"
          onClick={showImage ? handleNextStep : handleButtonClick}
          sx={{
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "10px",
            py: "14px",
            width: "100%",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
          }}>
          {showImage ? "Далее" : "Загрузить"}
        </Button>
      </Box>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleImageUpload}
      />
    </Box>
  );
};

export default Verification;
