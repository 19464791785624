import React, { useState } from "react";
import { Box, List, ListItem, ListItemText, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People"; // Добавляем значок для вкладки "Пользователи"
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const menuIcons = {
  Главная: <DashboardIcon />,
  Лоты: <DashboardIcon />,
  Заказы: <BusinessIcon />,
  Пользователи: <PeopleIcon />, // Добавляем значок для вкладки "Пользователи"
};

const AdminPanel = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const handleMenuClick = (event, item) => {
    if (item === "Лоты") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const isSubActive = (subPaths) => {
    return subPaths.some((path) => location.pathname.startsWith(path));
  };

  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box sx={{ width: 250, p: 2, background: "#FFF" }}>
          <List>
            <ListItem
              button
              component={NavLink}
              to="/admin/main"
              sx={{
                "&.active": {
                  backgroundColor: "rgba(47, 128, 237, 0.10)",
                  color: "#2F80ED",
                },
                borderRadius: "8px",
                mb: 1,
              }}
              isActive={() => isActive("/admin/main")}
              exact="true" //Добавил ="true"
              >
              <ListItemIcon
                sx={{ color: isActive("/admin/main") ? "#2F80ED" : "#89939E", minWidth: 32 }}>
                {menuIcons["Главная"]}
              </ListItemIcon>
              <ListItemText
                primary="Главная"
                primaryTypographyProps={{
                  fontSize: "14px",
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={(e) => handleMenuClick(e, "Лоты")}
              sx={{
                backgroundColor: isSubActive(["/admin/lots", "/admin/add-lot", "/admin/categories"])
                  ? "rgba(47, 128, 237, 0.10)"
                  : "transparent",
                "&.active": {
                  backgroundColor: "rgba(47, 128, 237, 0.10)",
                  color: "#2F80ED",
                },
                borderRadius: "8px",
                mb: 1,
              }}
              isActive={() => isSubActive(["/admin/lots", "/admin/add-lot", "/admin/categories"])}>
              <ListItemIcon
                sx={{
                  color: isSubActive(["/admin/lots", "/admin/add-lot", "/admin/categories"])
                    ? "#2F80ED"
                    : "#89939E",
                  minWidth: 32,
                }}>
                {menuIcons["Лоты"]}
              </ListItemIcon>
              <ListItemText
                primary="Лоты"
                primaryTypographyProps={{
                  fontSize: "14px",
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
              <ArrowRightIcon
                sx={{
                  color: isSubActive(["/admin/lots", "/admin/add-lot", "/admin/categories"])
                    ? "#2F80ED"
                    : "#89939E",
                }}
              />
            </ListItem>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  mt: 0,
                  ml: 2,
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  borderRadius: 2,
                  minWidth: 200,
                },
              }}>
              <MenuItem
                sx={{ fontWeight: 500, py: 1 }}
                component={NavLink}
                to="/admin/lots"
                onClick={handleMenuClose}>
                Все лоты
              </MenuItem>
              <MenuItem
                sx={{ fontWeight: 500, py: 1 }}
                component={NavLink}
                to="/admin/add-lot"
                onClick={handleMenuClose}>
                Добавить лот
              </MenuItem>
              <MenuItem
                sx={{ fontWeight: 500, py: 1 }}
                component={NavLink}
                to="/admin/categories"
                onClick={handleMenuClose}>
                Категории
              </MenuItem>
            </Menu>
            <ListItem
              button
              component={NavLink}
              to="/admin/orders"
              sx={{
                "&.active": {
                  backgroundColor: "rgba(47, 128, 237, 0.10)",
                  color: "#2F80ED",
                },
                borderRadius: "8px",
                mb: 1,
              }}
              isActive={() => isActive("/admin/orders")}>
              <ListItemIcon
                sx={{ color: isActive("/admin/orders") ? "#2F80ED" : "#89939E", minWidth: 32 }}>
                {menuIcons["Заказы"]}
              </ListItemIcon>
              <ListItemText
                primary="Заказы"
                primaryTypographyProps={{
                  fontSize: "14px",
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/admin/users"
              sx={{
                "&.active": {
                  backgroundColor: "rgba(47, 128, 237, 0.10)",
                  color: "#2F80ED",
                },
                borderRadius: "8px",
                mb: 1,
              }}
              isActive={() => isActive("/admin/users")}>
              <ListItemIcon
                sx={{ color: isActive("/admin/users") ? "#2F80ED" : "#89939E", minWidth: 32 }}>
                {menuIcons["Пользователи"]}
              </ListItemIcon>
              <ListItemText
                primary="Пользователи"
                primaryTypographyProps={{
                  fontSize: "14px",
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AdminPanel;
