import React, { Suspense, useEffect } from "react";
import AdminHeader from "./pages/AdminPanel/AdminHeader";
import AdminPanel from "./pages/AdminPanel/AdminPanel";

const AppLayout = () => {
  useEffect(() => {
    console.log("AdminLayout component mounted");
  }, []);
  return (
    <Suspense fallback={null}>
      <AdminHeader />
      <AdminPanel />
    </Suspense>
  );
};

export default React.memo(AppLayout);
