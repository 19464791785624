import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link as MuiLink, Typography, Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";
import { fetchCategories } from "../../services/CategoryService";

export default function BreadcrumbsComponent() {
  const { t } = useTranslation();
  const { auctionId } = useParams();
  const [auction, setAuction] = useState(null);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    const fetchAuction = async () => {
      try {
        const auctionItem = await AuctionItemService.fetchAuctionItemById(auctionId);
        setAuction(auctionItem);

        if (auctionItem && auctionItem.category) {
          const categories = await fetchCategories();
          const category = categories.find((cat) => cat._id === auctionItem.category);
          setCategoryName(category ? category.name : "");
        }
      } catch (error) {
        console.error("Error fetching auction item:", error);
      }
    };

    if (auctionId) {
      fetchAuction();
    }
  }, [auctionId]);

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <MuiLink component={Link} to="/" underline="hover" color="black">
          {t("breadcrumbs.home")}
        </MuiLink>
        <MuiLink component={Link} to="/lots" underline="hover" color="black">
          {t("breadcrumbs.categories")}
        </MuiLink>
        {categoryName && (
          <MuiLink
            component={Link}
            to={`/lots?category=${auction?.category}`}
            underline="hover"
            color="black">
            {categoryName}
          </MuiLink>
        )}
        {auction && auction.name && <Typography color="text.primary">{auction.name}</Typography>}
      </Breadcrumbs>
    </Box>
  );
}
