//import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider, GlobalStyles } from "@mui/material";
import theme from "./components/Theme/Theme";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          "*": {
            margin: 0,
            padding: 0,
            boxSizing: "border-box",
          },
          body: {
            fontFamily: "Roboto, sans-serif",
            backgroundColor: "#FAFBFF",
          },
        }}
      />
      <App />
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById("root"),
);
