import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { SearchProvider } from "./Context/SearchContext";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { FavoriteProvider } from "./Context/FavoriteContext";

const AppLayout = () => {
  return (
    <Suspense fallback={null}>
      <ScrollToTop />
      <SearchProvider>
        <FavoriteProvider>
          <Container className="container">
            <Header />
            <Outlet />
            <Footer />
          </Container>
        </FavoriteProvider>
      </SearchProvider>
    </Suspense>
  );
};

export default React.memo(AppLayout);
