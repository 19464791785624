import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const sections = t("privacyPolicy.sections", { returnObjects: true });

  return (
    <Box
      component="main"
      sx={{
        my: 6,
      }}>
      <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
        {t("privacyPolicy.title")}
      </Typography>

      {Array.isArray(sections) ? (
        sections.map((section, index) => (
          <Box sx={{ my: 4 }} key={index}>
            <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              {section.title}
            </Typography>

            {Array.isArray(section.content) &&
              section.content.map((text, idx) => (
                <Typography variant="body1" sx={{ fontSize: "14px" }} key={idx}>
                  {text.split("\n").map((str, i) => (
                    <React.Fragment key={i}>
                      {str}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              ))}
          </Box>
        ))
      ) : (
        <Typography variant="body1" sx={{ fontSize: "14px" }}>
          Ошибка: данные политики конфиденциальности не загружены.
        </Typography>
      )}
    </Box>
  );
};

export default PrivacyPolicy;
