import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemIcon,
  IconButton,
  Grid,
} from "@mui/material";
import { Gavel, ExitToApp, MenuRounded as MenuRoundedIcon } from "@mui/icons-material";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Context/AuthContext";

const ProfilePage = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const menuItems = [
    { label: t("profilePage.personalData"), icon: <PersonOutlinedIcon />, route: "personal-data" },
    {
      label: t("profilePage.balance"),
      icon: <AccountBalanceWalletOutlinedIcon />,
      route: "balance",
    },
    {
      label: t("profilePage.referralProgram"),
      icon: <CampaignOutlinedIcon />,
      route: "referral-program",
    },
    { label: t("profilePage.myAuctions"), icon: <Gavel />, route: "my-auctions" },
    {
      label: t("profilePage.myPurchases"),
      icon: <ShoppingCartOutlinedIcon />,
      route: "my-purchases",
    },
    { label: t("profilePage.favorites"), icon: <FavoriteBorderOutlinedIcon />, route: "favorites" },
  ];

  const handleMenuItemClick = (item) => {
    setMobileMenuOpen(false);
    if (item.route === "logout") {
      logout();
      navigate("/login");
    } else {
      navigate(`/profile/${item.route}`);
    }
  };

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <Paper
        component="main"
        sx={{
          px: 3,
          py: 3,
          my: 5,
          borderRadius: "20px",
          boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} sx={{ display: { xs: "block", md: "none" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}>
              <Typography fontSize={"18px"} variant="h6">
                {t("profilePage.profile")}
              </Typography>
              <IconButton
                onClick={handleMenuToggle}
                sx={{
                  mr: 2,
                  ml: 0,
                  display: { md: "none" },
                  color: "#3083FF",
                  p: 0,
                }}>
                <MenuRoundedIcon sx={{ fontSize: "48px" }} />
              </IconButton>
            </Box>
            {mobileMenuOpen && (
              <List>
                {menuItems.map((item) => (
                  <ListItem
                    button
                    key={item.label}
                    onClick={() => handleMenuItemClick(item)}
                    sx={{
                      backgroundColor: window.location.pathname.includes(item.route)
                        ? "rgba(47, 128, 237, 0.10)"
                        : "transparent",
                      "&:hover": {
                        backgroundColor: "#E6F7FF",
                      },
                      p: 1,
                    }}>
                    <ListItemIcon
                      sx={{
                        color: window.location.pathname.includes(item.route)
                          ? "#3083FF"
                          : "#89939E",
                        minWidth: 32,
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: window.location.pathname.includes(item.route) ? "#3083FF" : "#000",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    />
                  </ListItem>
                ))}
                <ListItem
                  button
                  onClick={() => handleMenuItemClick({ route: "logout" })}
                  sx={{
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "#E6F7FF",
                    },
                    p: 1,
                  }}>
                  <ListItemIcon sx={{ color: "#89939E", minWidth: 32 }}>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("profilePage.logout")}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#000",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item xs={12} md={2} sx={{ display: { xs: "none", md: "block" } }}>
            <Typography variant="h6" sx={{ fontSize: "18px", mb: 2, fontWeight: 400 }}>
              {t("profilePage.profile")}
            </Typography>
            <List>
              {menuItems.map((item) => (
                <ListItem
                  button
                  key={item.label}
                  component={NavLink}
                  to={`/profile/${item.route}`}
                  sx={{
                    "&.active": {
                      backgroundColor: "rgba(47, 128, 237, 0.10)",
                      color: "#2F80ED",
                    },
                    "& .MuiListItemIcon-root": {
                      color: "#89939E",
                    },
                    "&.active .MuiListItemIcon-root": {
                      color: "#2F80ED",
                    },
                    "& .MuiListItemText-root .MuiTypography-root": {
                      color: "#000",
                    },
                    "&.active .MuiListItemText-root .MuiTypography-root": {
                      color: "#2F80ED",
                    },
                    "&:hover": {
                      backgroundColor: "#E6F7FF",
                    },
                    p: 1,
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 32,
                    }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: 400,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                </ListItem>
              ))}
              <ListItem
                button
                onClick={() => handleMenuItemClick({ route: "logout" })}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "#E6F7FF",
                  },
                  p: 1,
                }}>
                <ListItemIcon sx={{ color: "#89939E", minWidth: 32 }}>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText
                  primary={t("profilePage.logout")}
                  primaryTypographyProps={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#000",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={10}>
            <Outlet />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ProfilePage;
