import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";
import { useSocket } from "../../Context/SocketContext";

const TopBidsTable = ({ auctionId, userId }) => {
  const { t } = useTranslation();
  const [bidders, setBidders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortMode, setSortMode] = useState("amount"); // amount or count
  const socket = useSocket();

  useEffect(() => {
    const loadTopBidders = async () => {
      try {
        const fetchedBidders = await AuctionItemService.fetchTopBidders(auctionId);
        const filteredBidders = fetchedBidders.filter((bidder) => !bidder.isParticipationFee);

        filteredBidders.sort((a, b) => b.lastBidAmount - a.lastBidAmount);
        setBidders(filteredBidders);
      } catch (error) {
        console.error("Ошибка при получении данных о ставках:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTopBidders();

    if (socket) {
      socket.on("update_bidders", (updatedData) => {
        if (updatedData.auctionId === auctionId) {
          setBidders((prevBidders) => {
            const existingBidderIndex = prevBidders.findIndex(
              (bidder) => bidder.user._id === updatedData.bidders[0].user._id,
            );

            if (existingBidderIndex >= 0) {
              const updatedBidders = [...prevBidders];
              updatedBidders[existingBidderIndex] = {
                ...updatedBidders[existingBidderIndex],
                bidCount: updatedData.bidders[0].bidCount,
                lastBidAmount: updatedData.bidders[0].lastBidAmount,
              };

              updatedBidders.sort((a, b) => b.lastBidAmount - a.lastBidAmount);
              return updatedBidders.filter((bidder) => !bidder.isParticipationFee);
            }

            return [...prevBidders, updatedData.bidders[0]].filter(
              (bidder) => !bidder.isParticipationFee,
            );
          });
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("update_bidders");
      }
    };
  }, [auctionId, socket]);

  const handleSort = () => {
    const newSortMode = sortMode === "amount" ? "count" : "amount";
    setSortMode(newSortMode);
    setBidders((prevBidders) =>
      [...prevBidders].sort((a, b) =>
        newSortMode === "amount" ? b.lastBidAmount - a.lastBidAmount : b.bidCount - a.bidCount,
      ),
    );
  };

  return (
    <Grid item xs={12} lg={3}>
      <Button
        variant="outlined"
        sx={{
          display: "block",
          mx: "auto",
          mb: 1.5,
          borderRadius: 2,
          textTransform: "none",
          borderColor: "#3083FF",
          color: "#3083FF",
          fontWeight: 400,
          width: "100%",
          fontSize: "14px",
        }}
        onClick={handleSort}>
        {sortMode === "amount" ? t("topBidsTable.activeParticipants") : t("topBidsTable.highBids")}
      </Button>
      <Box
        sx={{
          width: "288px",
          border: "1px solid #E3EEFF",
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
        }}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#E3EEFF",
            fontSize: "20px",
            p: 1,
            pr: 0,
            fontWeight: 600,
          }}>
          {sortMode === "amount" ? t("topBidsTable.highBids") : "Топ 10 активных участников"}
        </Typography>
        {loading ? (
          <Typography
            sx={{
              p: 1.5,
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 400,
              backgroundColor: "#FFF",
            }}>
            {t("loading")}
          </Typography>
        ) : bidders.length === 0 ? (
          <Typography
            sx={{
              p: 1.5,
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 400,
              backgroundColor: "#FFF",
            }}>
            нет участников
          </Typography>
        ) : (
          <>
            <Typography
              sx={{
                p: 1.5,
                textAlign: "right",
                fontSize: "12px",
                fontWeight: 400,
                backgroundColor: "#FFF",
              }}>
              {`Пользователей: ${bidders.length}`}
            </Typography>
            <TableContainer component={Paper} sx={{ p: 1, pt: 0 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 400,
                        fontSize: "10px",
                        padding: "3px",
                        borderBottom: "none",
                      }}>
                      {t("topBidsTable.rank")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 400,
                        fontSize: "10px",
                        padding: "3px",
                        borderBottom: "none",
                      }}>
                      {t("topBidsTable.login")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 400,
                        fontSize: "10px",
                        padding: "3px",
                        borderBottom: "none",
                      }}>
                      {t("topBidsTable.bids")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 400,
                        fontSize: "10px",
                        padding: "3px",
                        borderBottom: "none",
                      }}>
                      {t("topBidsTable.bidAmount")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 400,
                        fontSize: "10px",
                        padding: "3px",
                        borderBottom: "none",
                      }}>
                      {t("topBidsTable.city")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bidders.map((bidder, index) => (
                    <TableRow
                      key={bidder.user._id}
                      sx={{
                        backgroundColor: bidder.user._id === userId ? "#E3FCEF" : "#FFF",
                        borderBottom: "1px solid #E3EEFF",
                      }}>
                      <TableCell sx={{ fontSize: "10px", padding: "5px" }}>{index + 1}</TableCell>
                      <TableCell sx={{ fontSize: "10px", padding: "5px" }}>
                        {bidder.user._id === userId ? "Вы" : bidder.user.firstName || "No Name"}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px", padding: "5px" }}>
                        {bidder.bidCount || 0}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px", padding: "5px" }}>
                        {bidder.lastBidAmount
                          ? `${bidder.lastBidAmount.toLocaleString()} ${t("currency")}`
                          : "Нет ставок"}
                      </TableCell>
                      <TableCell sx={{ fontSize: "10px", padding: "5px" }}>
                        {bidder.user.city || "No City"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default TopBidsTable;
