import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Crumbs from "../../components/Crums/Crums";
import Info from "../../components/Info/Info";
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import Rate from "../../components/Rate/Rate";
import Description from "../../components/Description/Description";
import TopBidsTable from "../../components/TopBidsTable/TopBidsTable";
import { AuctionItemService } from "../../services/AuctionItemService";
import { UserService } from "../../services/UserService";
import { useSocket } from "../../Context/SocketContext";

const Product = () => {
  const { auctionId } = useParams();
  const [auction, setAuction] = useState(null);
  const [userId, setUserId] = useState(null);
  const socket = useSocket();

  const fetchAuction = async () => {
    try {
      const data = await AuctionItemService.fetchAuctionItemById(auctionId);
      setAuction(data);
    } catch (error) {
      console.error("Error fetching auction data:", error);
    }
  };

  useEffect(() => {
    fetchAuction();

    const fetchUserId = async () => {
      try {
        const userService = new UserService();
        const responseUser = await userService.getUserId();
        setUserId(responseUser);
      } catch (error) {
        console.log("Пользователь не зарегистрирован или произошла ошибка получения userId");
        setUserId(null);
      }
    };

    fetchUserId();

    if (socket) {
      socket.on("update_bidders", (updatedData) => {
        if (updatedData.auctionId === auctionId) {
          fetchAuction();
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("update_bidders");
      }
    };
  }, [auctionId, socket]);

  if (!auction) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Crumbs />
      <Info auction={auction} />
      <Grid container spacing={1.5}>
        <ProductSlider auctionId={auctionId} />
        <Rate auction={auction} userId={userId} />
        <TopBidsTable auctionId={auction._id} userId={userId} />
      </Grid>
      <Description description={auction.description} />
    </>
  );
};

export default Product;
