import axiosInstance from "../axiosConfig";
export interface AuctionItem {
  _id?: string;
  name: string;
  description?: string;
  category?: string;
  startingPrice?: number;
  increment?: number;
  startDate?: string;
  endDate?: string;
  currentPrice?: number;
  image?: string;
  status?: "inactive" | "active" | "completed";
}

export class AuctionItemService {
  static async createAuction(auctionData: FormData): Promise<AuctionItem> {
    try {
      const response = await axiosInstance.post<AuctionItem>("/api/auction-items", auctionData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating auction:", error);
      throw error;
    }
  }

  static async fetchAuctionItems(): Promise<AuctionItem[]> {
    try {
      const response = await axiosInstance.get<AuctionItem[]>("/api/auction-items");
      return response.data;
    } catch (error) {
      console.error("Error fetching auction items:", error);
      throw error;
    }
  }

  static async fetchAuctionItemById(id: string): Promise<AuctionItem> {
    try {
      console.log('fetchAuctionItemById')
      const response = await axiosInstance.get<AuctionItem>(`/api/auction-items/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching auction item by ID:", error);
      throw error;
    }
  }
  static async fetchExtraChargeForItemById(id: string): Promise<any> {
    try {
      console.log('fetchAuctionItemById')
      const response = await axiosInstance.get(`/api/bids/total/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching auction item by ID:", error);
      throw error;
    }
  }

  static async updateAuctionItem(
    id: string,
    updatedItem: Partial<AuctionItem>,
  ): Promise<AuctionItem> {
    try {
      const response = await axiosInstance.patch<AuctionItem>(
        `/api/auction-items/${id}`,
        updatedItem,
      );
      return response.data;
    } catch (error) {
      console.error("Error updating auction item:", error);
      throw error;
    }
  }

  static async updateAuctionItemStatus(
    id: string,
    status: { status: string },
  ): Promise<AuctionItem> {
    try {
      const response = await axiosInstance.patch<AuctionItem>(`/api/auction-items/${id}`, status);
      console.log('отправили повестку на бэк')
      return response.data;
    } catch (error) {
      console.error("Error updating auction item status:", error);
      throw error;
    }
  }

  static async fetchMyAuctions(): Promise<AuctionItem[]> {
    try {
      console.log("Fetching user's auctions...");
      const response = await axiosInstance.get<AuctionItem[]>("/api/auction-items/my-auctions");
      console.log("Auctions received:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user's auctions:", error);
      throw error;
    }
  }

  static async fetchMyPurchases(): Promise<AuctionItem[]> {
    try {
      const response = await axiosInstance.get("/api/auction-items/my-purchases");
      return response.data;
    } catch (error) {
      console.error("Error fetching user's purchases:", error);
      throw error;
    }
  }

  static async markAsPaid(auctionItemId: string): Promise<void> {
    try {
      const response = await axiosInstance.post(
        `/api/auction-items/${auctionItemId}/mark-as-paid`,
        {
          paymentStatus: "paid",
        },
      );

      await AuctionItemService.refundBids(auctionItemId);

      return response.data;
    } catch (error) {
      console.error("Error marking auction as paid or refunding bids:", error);
      throw error;
    }
  }

  static async placeBid(auctionItemId: string, amount: number, isSuperBid: boolean): Promise<any> {
    try {
      const response = await axiosInstance.post(`/api/auction-items/${auctionItemId}/bid`, {
        amount, isSuperBid
      });
      console.log("Bid placed successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error placing bid:", error);
      throw error;
    }
  }

  static async fetchUserLastBid(auctionItemId: string): Promise<any> {
    try {
      const response = await axiosInstance.get(`/api/auction-items/${auctionItemId}/my-last-bid`);
      console.log('fetchUserLastBid')
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching user's last bid:", error);
      throw error;
    }
  }

  static async fetchTopBidders(auctionItemId: string): Promise<any> {
    try {
      const response = await axiosInstance.get(`/api/auction-items/top/${auctionItemId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching top bidders:", error);
      throw error;
    }
  }

  static async refundBids(auctionItemId: string): Promise<void> {
    try {
      const response = await axiosInstance.post(`/api/auction-items/${auctionItemId}/refund`);
      return response.data;
    } catch (error) {
      console.error("Error refunding bids:", error);
      throw error;
    }
  }

  static async deleteAuctionItem(id: string): Promise<void> {
    try {
      await axiosInstance.delete(`/api/auction-items/${id}`);
    } catch (error) {
      console.error("Error deleting auction item:", error);
      throw error;
    }
  }
}
