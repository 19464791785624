import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PersonOutlined as PersonOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  ExitToApp as ExitToAppIcon,
  Gavel as GavelIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Context/AuthContext";
import coin from "../../assets/images/coin 1.svg";
import star from "../../assets/images/Star 1.svg";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import SearchButton from "../SearchButton/SearchButton";
import { WalletService } from "../../services/WalletService";
import { UserService } from "../../services/UserService";

const Authorized = ({ onSearch }) => {
  const { logout, user, role, setUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await new UserService().getUserId();
        if (userData) {
          const response = await UserService.fetchUsers();
          const user = response.find((u) => u._id === userData);
          setUser(user);

          const nameToDisplay =
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.firstName || "User";

          setDisplayName(nameToDisplay);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [setUser]);

  useEffect(() => {
    if (user) {
      fetchBalance();
      const intervalId = setInterval(fetchBalance, 10000);

      return () => clearInterval(intervalId);
    }
  }, [user]);

  const fetchBalance = async () => {
    try {
      const fetchedBalance = await WalletService.getWalletBalance();
      setBalance(fetchedBalance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  const menuItems = [
    {
      text: t("authorized.personalData"),
      icon: <PersonOutlinedIcon />,
      route: "/profile/personal-data",
    },
    {
      text: t("authorized.balance"),
      icon: <AccountBalanceWalletOutlinedIcon />,
      route: "/profile/balance",
    },
    { text: t("authorized.myAuctions"), icon: <GavelIcon />, route: "/profile/my-auctions" },
    {
      text: t("authorized.myPurchases"),
      icon: <ShoppingCartOutlinedIcon />,
      route: "/profile/my-purchases",
    },
    {
      text: t("authorized.favorites"),
      icon: <FavoriteBorderOutlinedIcon />,
      route: "/profile/favorites",
    },
    { text: t("authorized.logout"), icon: <ExitToAppIcon />, action: logout },
  ];

  const handleMenuOpen = (event) => {
    console.log("Menu open clicked. Event:", event);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    console.log("Menu closed.");
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route, action) => {
    if (action) {
      action();
      navigate("/");
    }
    if (route) {
      console.log(`Navigating to route: ${route}`);
      if (user && role === "Admin" && route === "/profile/personal-data") {
        route = "/admin/main"; // Редирект на админскую панель
        console.log("Redirecting to admin route due to admin role.");
      }
      navigate(route);
    }
    handleMenuClose();
  };

  const handleUserClick = (event) => {
    console.log("User icon clicked. Role:", role);
    if (role === "Admin") {
      console.log("User is admin. Navigating to admin main page.");
      navigate("/admin/main");
    } else {
      console.log("User is not admin. Opening user menu.");
      handleMenuOpen(event);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
      <SearchButton onSearch={onSearch} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#3083FF",
          padding: "4px 12px",
          borderRadius: 7,
          mr: "12px",
        }}>
        <Box>
          <img src={coin} alt="coin" />
        </Box>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#fff", pl: 1 }}>
          {balance}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #3083FF",
          borderRadius: 2,
          padding: "8px 12px",
          mr: "12px",
          color: "#3083FF",
          position: "relative",
          py: 0.5,
          cursor: "pointer",
        }}
        onClick={handleUserClick}>
        <PersonOutlinedIcon sx={{ fontSize: "16px" }} />
        <Typography sx={{ px: 1, fontSize: "14px", fontWeight: 400 }}>{displayName}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            width: "24px",
            height: "24px",
          }}>
          <img src={star} alt="star" style={{ width: "80%", height: "100%" }} />
          <Typography
            sx={{
              position: "absolute",
              top: "55%",
              left: "40%",
              transform: "translate(-50%, -50%)",
              fontSize: "8px",
              fontWeight: "bold",
              color: "#fff",
            }}>
            10
          </Typography>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "190px",
            mt: 1.5,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          },
        }}>
        {menuItems.map((item, index) => (
          <MenuItem
            sx={{ py: 1 }}
            key={index}
            onClick={() => handleMenuItemClick(item.route, item.action)}>
            <ListItemIcon sx={{ minWidth: "32px" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>

      <IconButton
        sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
        <NotificationsOutlinedIcon sx={{ fontSize: "16px" }} />
      </IconButton>

      <LanguageSwitcher />
    </Box>
  );
};

export default Authorized;
