import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 9;

const MyShopping = () => {
  const [page, setPage] = useState(1);
  const [purchases, setPurchases] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const loadMyPurchases = async () => {
      try {
        const purchasesData = await AuctionItemService.fetchMyPurchases();
        const formattedPurchases = purchasesData.map((auction) => ({
          lot: auction.lot,
          date: new Date(auction.date).toLocaleDateString(),
          status: auction.isPaid ? t("myShopping.paid") : t("myShopping.unpaid"),
          product: auction.name,
          amount: `${auction.currentPrice} ${t("myShopping.currency")}`,
          paid: auction.isPaid,
        }));
        setPurchases(formattedPurchases);
      } catch (error) {
        console.error("Ошибка при загрузке покупок:", error);
      }
    };

    loadMyPurchases();
  }, [t]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedPurchases = purchases.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px", overflow: "auto" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
        {t("myShopping.title")}
      </Typography>
      {/* Контейнер с горизонтальной прокруткой для таблицы */}
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650, my: 4 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myShopping.lotNumber")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myShopping.date")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myShopping.status")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myShopping.product")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myShopping.amount")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedPurchases.map((purchase, index) => (
              <TableRow
                key={index}
                sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}>
                <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.lot}</TableCell>
                <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.date}</TableCell>
                <TableCell sx={{ borderBottom: "none", py: 1.5 }}>
                  <Box
                    sx={{
                      backgroundColor: purchase.paid ? "#D9F8E5" : "#FDE6E3",
                      borderRadius: "8px",
                      p: "5px 10px",
                      textAlign: "center",
                      width: "fit-content",
                    }}>
                    {purchase.status}
                  </Box>
                </TableCell>
                <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.product}</TableCell>
                <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(purchases.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default MyShopping;
