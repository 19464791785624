import React from "react";
import "./Main.scss";
import Banner from "../../components/Banner/Banner";
import Nearest from "../../components/Nearest/Nearest";
import BuyNow from "../../components/BuyNow/BuyNow";
import Categories from "../../components/Categoties/Categories";

export default function Main() {
  return (
    <>
      <Banner />
      <Categories />
      <Nearest />
      <BuyNow />
    </>
  );
}
