import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const OfferTerms = () => {
  const { t } = useTranslation();

  const sections = t("offerTerms.sections", { returnObjects: true });

  return (
    <Box
      component="main"
      sx={{
        my: 6,
      }}>
      <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
        {t("offerTerms.title")}
      </Typography>

      <Typography variant="body1" sx={{ fontSize: "14px" }}>
        {
          "ВНИМАНИЕ: пожалуйста, ознакомьтесь с настоящим пользовательским соглашением до начала использования Платформы «Brama.kz». Использование названных объектов будет означать ваше согласие с условиями настоящего пользовательского соглашения, а акцепт будет считаться безоговорочным согласием принятий нижеследующих условий договора оферты. Если Вы не согласны с условиями настоящего пользовательского соглашения, не используйте функции участия в лотах платформы «Brama.kz», включая пополнения баланса личного кабинета."
        }
        <br />
        <br />
      </Typography>

      <Typography variant="body1" sx={{ fontSize: "14px", float: "left" }}>
        {"г.Алматы."}
      </Typography>

      <Typography variant="body1" sx={{ fontSize: "14px", float: "right" }}>
        {"Редакция от 09.09.2024 г."}
      </Typography>

      <Typography variant="body1" sx={{ fontSize: "14px" }}>
        <br />
        <br />
        <p>
          {
            "Платформа Brama.kz, с одной стороны, и Пользователь, принявший условия использования Платформы другой стороны (Далее – Стороны), заключили настоящее пользовательское соглашение (Далее – Соглашение) о нижеследующем."
          }
        </p>
      </Typography>

      {Array.isArray(sections) ? (
        sections.map((section, index) => (
          <Box sx={{ my: 4 }} key={index}>
            <Typography variant="h2" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
              {section.title}
            </Typography>

            {Array.isArray(section.content) &&
              section.content.map((text, idx) => (
                <Typography variant="body1" sx={{ fontSize: "14px" }} key={idx}>
                  {text.split("\n").map((str, i) => (
                    <React.Fragment key={i}>
                      {str}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              ))}
          </Box>
        ))
      ) : (
        <Typography variant="body1" sx={{ fontSize: "14px" }}>
          Ошибка: данные условий оферты не загружены.
        </Typography>
      )}
    </Box>
  );
};

export default OfferTerms;
