import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        component="main"
        sx={{
          my: 6,
        }}>
        <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
          {t("contacts.title")}
        </Typography>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", fontWeight: 600 }}>
            {t("contacts.contactCenter.title")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px", my: 2 }}>
            {t("contacts.contactCenter.phone")}{" "}
            <Link href="tel:+77006543243">{t("contacts.contactCenter.phoneNumber")}</Link>
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.contactCenter.email")}
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            {t("contacts.address.title")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.address.details")}
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            {t("contacts.companyDetails.title")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.companyDetails.address")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.companyDetails.bin")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.companyDetails.accountNumber")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.companyDetails.bank")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.companyDetails.bic")}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            {t("contacts.companyDetails.kbe")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Contacts;
