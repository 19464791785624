import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 6;

const MyAuctions = () => {
  const [page, setPage] = useState(1);
  const [auctions, setAuctions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        console.log("Fetching user's auctions...");

        const response = await AuctionItemService.fetchMyAuctions();

        console.log("Auctions received from server:", response);

        if (response.length === 0) {
          console.warn("No auctions were returned by the server.");
        }

        setAuctions(response);
      } catch (error) {
        console.error("Error fetching user's auctions:", error);
      }
    };

    fetchAuctions();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedAuctions = auctions.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px", overflow: "auto" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        {t("myAuctions.title")}
      </Typography>
      {/* Контейнер с горизонтальной прокруткой для таблицы */}
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650, my: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  pl: 7,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myAuctions.lotNumber")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  pl: 5,
                  maxWidth: "160px",
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myAuctions.product")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  pr: 5,
                  maxWidth: "100px",
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myAuctions.date")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  pl: 0,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myAuctions.bid")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  py: 1.5,
                  pl: 8,
                  pr: 2,
                  borderBottom: "1px solid #ABBED1",
                }}>
                {t("myAuctions.status")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedAuctions.length > 0 ? (
              paginatedAuctions.map((auction, index) => (
                <TableRow
                  key={index}
                  sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}>
                  <TableCell sx={{ borderBottom: "none", py: 1.5 }}>
                    <IconButton size="small" sx={{ color: "#3083FF" }}>
                      <AddIcon sx={{ fontSize: "16px", mr: 2 }} />
                    </IconButton>
                    {auction._id}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none", pl: 5, maxWidth: "164px" }}>
                    {auction.name}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {new Date(auction.endDate).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none", pl: 0 }}>
                    {auction.currentPrice} {t("currency")}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{auction.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                  {t("myAuctions.noAuctions")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(auctions.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default MyAuctions;
