import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Menu, MenuItem, Drawer } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchCategories } from "../../services/CategoryService";
import { useAuth } from "../../Context/AuthContext";
import UnauthorizedMobile from "../UnauthorizedMobile/UnauthorizedMobile";
import AuthorizedMobile from "../AuthorizedMobile/AuthorizedMobile";

export default function DrawerHeader({ drawerOpen, toggleDrawer }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    toggleDrawer();
    navigate(`/lots?category=${categoryId}`);
  };

  const navigateToMain = () => {
    toggleDrawer();
    navigate("/");
  };

  const navigateToTerms = () => {
    toggleDrawer();
    navigate("/terms");
  };

  const navigateToPayDeliv = () => {
    toggleDrawer();
    navigate("/payment-and-delivery");
  };

  const navigateToContacts = () => {
    toggleDrawer();
    navigate("/contacts");
  };

  const navigateToLogin = () => {
    toggleDrawer();
    navigate("/login");
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleUserIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
      <div role="presentation" onClick={(e) => e.stopPropagation()} onKeyDown={toggleDrawer}>
        <IconButton
          className="logo"
          sx={{
            py: 3,
            px: 2,
            display: "block",
            color: "#3083FF",
          }}
          onClick={toggleDrawer} // перенесли из CloseRoundedIcon
          >
          <CloseRoundedIcon sx={{ fontSize: "48px" }}  />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          onClick={navigateToMain}
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          {t("drawerHeader.main")}
        </Typography>

        <Typography
          id="category-button"
          aria-controls={menuOpen ? "category-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={handleClickMenu}
          //disableElevation // у Typography не может быть такого атрибута ?!
          variant="h6"
          noWrap
          component="a"
          href="#"
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "flex",
            cursor: "pointer",
          }}>
          {t("drawerHeader.categories")}
          <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <ArrowDropDownIcon sx={{ pb: "3px" }} />
          </Box>
        </Typography>

        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && menuOpen}  // Добавляем проверку на существование anchorEl
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "category-button",
          }}
          PaperProps={{
            sx: {
              mt: 1.5,
              ml: -1.5,
              boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
              borderRadius: 2,
              minWidth: 165,
            },
          }}>
          {categories.map((category) => (
            <MenuItem
              key={category._id}
              sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }}
              onClick={() => handleCategoryClick(category._id)}>
              {category.name}
            </MenuItem>
          ))}
        </Menu>

        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          onClick={navigateToTerms}
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          {t("drawerHeader.terms")}
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          onClick={navigateToPayDeliv}
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          {t("drawerHeader.paymentAndDelivery")}
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          onClick={navigateToContacts}
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          {t("drawerHeader.contacts")}
        </Typography>
        {user ? (
          <AuthorizedMobile onUserIconClick={handleUserIconClick} toggleDrawer={toggleDrawer} />
        ) : (
          <UnauthorizedMobile onUserIconClick={navigateToLogin} />
        )}
      </div>
    </Drawer>
  );
}
