import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, MenuItem, TextField } from "@mui/material";
import axiosInstance from "../../axiosConfig";
import Money_Img from "../../assets/images/money.svg";
import Green_Img from "../../assets/images/wallet_green.svg";
import Blue_Img from "../../assets/images/wallet_blue.svg";
import Red_Img from "../../assets/images/wallet_red.svg";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    background: "#FFFFFF",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
  minWidth: 250,
};

const MainAdmin = () => {
  const [period, setPeriod] = useState("24_hours");
  const [stats, setStats] = useState({
    totalCredit: 0,
    totalDebit: 0,
    totalBalance: 0,
  });

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const fetchSummary = async () => {
    try {
      const response = await axiosInstance.get("/api/wallets/summary", {
        params: { period },
      });
      setStats(response.data);
    } catch (error) {
      console.error("Error fetching wallet summary:", error);
    }
  };

  useEffect(() => {
    fetchSummary();
  }, [period]);

  const statItems = [
    { id: 1, label: "Поступило на кошелек", value: `${stats.totalCredit} тг`, img: Green_Img },
    { id: 2, label: "Получено за участие в аукционах", value: "0 тг", img: Money_Img },
    { id: 3, label: "Осталось на кошельках", value: `${stats.totalBalance} тг`, img: Blue_Img },
    { id: 4, label: "Потрачено с кошелька", value: `${stats.totalDebit} тг`, img: Red_Img },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
        <TextField
          select
          value={period}
          onChange={handlePeriodChange}
          variant="outlined"
          sx={textFieldStyles}
          displayEmpty
          SelectProps={{
            displayEmpty: true,
            renderValue: (value) => {
              switch (value) {
                case "24_hours":
                  return "Последние 24 часа";
                case "week":
                  return "Неделя";
                case "month":
                  return "Месяц";
                case "year":
                  return "Год";
                case "all_time":
                default:
                  return "Все время";
              }
            },
          }}>
          <MenuItem value="24_hours">Последние 24 часа</MenuItem>
          <MenuItem value="week">Неделя</MenuItem>
          <MenuItem value="month">Месяц</MenuItem>
          <MenuItem value="year">Год</MenuItem>
          <MenuItem value="all_time">Все время</MenuItem>
        </TextField>
      </Box>
      <Grid container spacing={2}>
        {statItems.map((stat) => (
          <Grid item xs={12} md={6} lg={3} key={stat.id}>
            <Box
              sx={{
                border: "1px solid #ABBED1",
                borderRadius: "20px",
                pt: 1,
                pb: 2,
                px: 3,
                background: "#FFF",
              }}>
              <Box sx={{ mt: 2 }}>
                <img src={stat.img} alt="" style={{ width: "74px" }} />
                <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 500, mt: 1 }}>
                  {stat.label}
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ color: "#3083FF", fontSize: "36px", fontWeight: 600 }}>
                {stat.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MainAdmin;
