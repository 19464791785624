import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { lotStatus } from "../../DB/Sidebar";
import { fetchCategories } from "../../services/CategoryService";

const Sidebar = ({ onCategoryChange, onStatusChange, selectedCategories, selectedStatus }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, []);

  return (
    <Grid item xs={12} md={3} sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ fontSize: "28px", fontWeight: 600, mb: 2 }}>
        {t("sidebar.categoriesTitle")}
      </Typography>
      <Box>
        {categories.map((category) => (
          <Typography
            key={category._id}
            sx={{
              fontSize: "18px",
              fontWeight: selectedCategories.includes(category._id) ? 800 : 400,
              py: 1,
              cursor: "pointer",
            }}
            onClick={() => onCategoryChange(category._id)}>
            {category.name}
          </Typography>
        ))}
      </Box>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t("sidebar.showLots")}
      </Typography>
      <Box>
        {lotStatus.map((status) => (
          <FormControlLabel
            key={status.key}
            control={
              <Checkbox
                onChange={() => onStatusChange(status.key)}
                checked={selectedStatus.includes(status.key)}
                sx={{ "& .MuiSvgIcon-root": { fontSize: "24px" } }}
              />
            }
            label={status.label}
            sx={{ display: "block", fontSize: "16px", fontWeight: 400, my: 1 }}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default Sidebar;
