import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function FundsModal({ open, onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToBalance = () => {
    onClose();
    navigate("/profile/balance");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 3,
            textAlign: "center",
            minWidth: 476,
          },
        }}>
        <DialogTitle id="modal-title" sx={{ fontSize: "20px", fontWeight: 600 }}>
          Недостаточно средств на балансе
        </DialogTitle>
        <DialogContent sx={{ fontSize: "16px", fontWeight: 600 }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", fontWeight: 400, color: "black" }}
            gutterBottom>
            Пополните баланс кошелька в вашем личном кабинете
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            gap: 1,
            "& > :not(style) ~ :not(style)": {
              marginLeft: "0 !important",
            },
          }}>
          <Button
            variant="contained"
            onClick={handleNavigateToBalance}
            sx={{
              width: "100%",
              borderRadius: 2,
              py: 1,
              background: "linear-gradient(to right, #00c6ff, #0072ff)",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
              m: 0,
            }}>
            {t("fundsModal.replenish")}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              width: "100%",
              borderRadius: 2,
              py: 1,
              borderColor: "#0072ff",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
            }}>
            {t("fundsModal.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FundsModal;
