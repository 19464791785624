import React, { createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { useAuth } from "./AuthContext"; // Импортируем AuthContext для проверки авторизации

const FavoriteContext = createContext();

export const useFavorite = () => useContext(FavoriteContext);

export const FavoriteProvider = ({ children }) => {
  const { user } = useAuth(); // Достаем информацию о пользователе из контекста авторизации
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    if (user) {  // Проверяем, авторизован ли пользователь
    fetchFavorites();
    }
  }, [user]);

  const fetchFavorites = async () => {
    try {
      const response = await axiosInstance.get("/api/favorites");
      setFavorites(response.data);
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };

  const addFavorite = async (card) => {
    try {
      const response = await axiosInstance.post("/api/favorites", { itemId: card._id });
      setFavorites((prevFavorites) => [...prevFavorites, response.data]);
    } catch (error) {
      console.error("Error adding favorite:", error);
    }
  };

  const removeFavorite = async (card) => {
    try {
      await axiosInstance.delete(`/api/favorites/${card._id}`);
      setFavorites((prevFavorites) =>
        prevFavorites.filter((favorite) => favorite._id !== card._id),
      );
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };

  return (
    <FavoriteContext.Provider value={{ favorites, addFavorite, removeFavorite }}>
      {children}
    </FavoriteContext.Provider>
  );
};
