import React from "react";
import { Grid, Typography, Tooltip, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import gift from "../../assets/images/gift.gif";

const CustomTooltip = ({ title, children }) => (
  <Tooltip
    title={
      <Box
        sx={{
          p: 2,
          bgcolor: "#fff",
          color: "#000",
          borderRadius: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          width: "430px",
          maxWidth: "none",
        }}>
        <Typography variant="body2" sx={{ textAlign: "center", fontSize: "12px" }}>
          {title}
        </Typography>
      </Box>
    }
    placement="bottom"
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: "transparent",
          "& .MuiTooltip-arrow": {
            color: "#fff",
          },
          maxWidth: "none",
        },
      },
      arrow: {
        sx: {
          color: "#fff",
        },
      },
    }}>
    {children}
  </Tooltip>
);

export default function Info() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        justifyContent: "space-between",
        background: "linear-gradient(90deg, #EA610B 0%, #FF9A5A 100%)",
        borderRadius: 5,
        color: "#fff",
        alignItems: "center",
        width: "100%",
        mt: 5,
        mb: 3,
      }}>
      <Grid item xs={11}>
        <Grid container alignItems="center" justifyContent="center">
          <img src={gift} alt="gif" style={{ width: "70px" }} />
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("info.mainText")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
        <CustomTooltip title={t("info.tooltipText")}>
          <InfoOutlinedIcon sx={{ cursor: "pointer", color: "#fff" }} />
        </CustomTooltip>
      </Grid>
    </Grid>
  );
}
