import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./Context/AuthContext";

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { user, role } = useAuth();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (adminOnly && role !== "Admin") {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
