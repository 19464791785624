import React from "react";
import { Box, Typography } from "@mui/material";

export default function Description({ description }) {
  return (
    <Box sx={{ my: 12 }}>
      <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: "bold" }}>
        Описание
      </Typography>
      <Box sx={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: description }} />
    </Box>
  );
}
