import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Grid, Snackbar, Alert } from "@mui/material";
import ReferralImage from "../../assets/images/Group 2287.png";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Context/AuthContext";

const ReferralProgram = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (user && !user.referralCode) {
      console.error("Referral code is missing for the user.");
    } else {
      console.log("Referral code found:", user?.referralCode);
    }
  }, [user]);

  const referralLink = user?.referralCode
    ? `${window.location.origin}/register?ref=${user.referralCode}`
    : "";

  const handleCopy = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink);
      setOpenSnackbar(true);
    } else {
      console.error("Referral link is not available");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <Box sx={{ width: "65%" }}>
        <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
          {t("referralProgram.title")}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "20px", mt: 10, mb: 2, fontWeight: 600 }}>
          {t("referralProgram.shareLinkOrCode")}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
          <TextField
            sx={{
              width: "50%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                borderColor: "#89939E",
                "& fieldset": {
                  borderColor: "#89939E",
                },
              },
              "& .MuiInputBase-input": {
                py: "10px",
              },
            }}
            value={referralLink}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            onClick={handleCopy}
            sx={{
              width: "50%",
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "6px 24px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            {t("referralProgram.copy")}
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              {t("referralProgram.step")} 1
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#000" }}>
              {t("referralProgram.step1Description")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              {t("referralProgram.step")} 2
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#000" }}>
              {t("referralProgram.step2Description")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              {t("referralProgram.step")} 3
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#000" }}>
              {t("referralProgram.step3Description")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "35%", mt: 12 }}>
        <img src={ReferralImage} alt={t("referralProgram.imageAlt")} style={{ width: "100%" }} />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert severity="success" sx={{ width: "100%", bgcolor: "#E6F7FF" }}>
          {t("referralProgram.linkCopied")}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReferralProgram;
