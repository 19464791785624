import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PersonOutlined as PersonOutlinedIcon,
  ExitToApp as ExitToAppIcon,
  Gavel as GavelIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
} from "@mui/icons-material";
import coin from "../../assets/images/coin 1.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Context/AuthContext";
import { WalletService } from "../../services/WalletService";
import { UserService } from "../../services/UserService";

const AuthorizedMobile = ({ toggleDrawer }) => {
  const { logout, role, setUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState("");
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await new UserService().getUserId();
        if (userId) {
          const response = await UserService.fetchUsers();
          const user = response.find((u) => u._id === userId);
          setUser(user);

          const nameToDisplay =
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user.firstName || "User";
          setDisplayName(nameToDisplay);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [setUser]);

  useEffect(() => {
    fetchBalance();
    const intervalId = setInterval(fetchBalance, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchBalance = async () => {
    try {
      const fetchedBalance = await WalletService.getWalletBalance();
      setBalance(fetchedBalance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  const menuItems = [
    {
      text: t("authorized.personalData"),
      icon: <PersonOutlinedIcon />,
      route: "/profile/personal-data",
    },
    {
      text: t("authorized.balance"),
      icon: <AccountBalanceWalletOutlinedIcon />,
      route: "/profile/balance",
    },
    { text: t("authorized.myAuctions"), icon: <GavelIcon />, route: "/profile/my-auctions" },
    {
      text: t("authorized.myPurchases"),
      icon: <ShoppingCartOutlinedIcon />,
      route: "/profile/my-purchases",
    },
    {
      text: t("authorized.favorites"),
      icon: <FavoriteBorderOutlinedIcon />,
      route: "/profile/favorites",
    },
    { text: t("authorized.logout"), icon: <ExitToAppIcon />, action: logout },
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route, action) => {
    if (action) {
      action();
      navigate("/");
    }
    if (route) {
      if (role === "Admin" && route === "/profile/personal-data") {
        route = "/admin/main";
      }
      navigate(route);
    }
    toggleDrawer();
    handleMenuClose();
  };

  const handleUserClick = (event) => {
    if (role === "Admin") {
      navigate("/admin/main");
    } else {
      handleMenuOpen(event);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
      <Box sx={{ ml: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#3083FF",
            padding: "4px 12px",
            borderRadius: 7,
            mr: "12px",
            mb: 1,
          }}>
          <Box>
            <img src={coin} alt="coin" />
          </Box>
          <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#fff", pl: 1 }}>
            {balance}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #3083FF",
            borderRadius: 2,
            padding: "8px 12px",
            mr: "12px",
            color: "#3083FF",
            cursor: "pointer",
          }}
          onClick={handleUserClick}>
          <PersonOutlinedIcon sx={{ fontSize: "24px" }} />
          <Typography sx={{ px: 1, fontSize: "14px", fontWeight: 400 }}>{displayName}</Typography>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "190px",
            mt: 1.5,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          },
        }}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(item.route, item.action)}>
            <ListItemIcon sx={{ minWidth: "32px" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AuthorizedMobile;
