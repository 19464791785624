// LanguageSwitcher.jsx
import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "ru" ? "en" : "ru";
    i18n.changeLanguage(newLang);
  };

  return (
    <Button
      sx={{
        padding: "4px 22px",
        color: "#3083FF",
        fontSize: "14px",
        fontWeight: 400,
        border: "1px solid #3083FF",
        borderRadius: 2,
      }}
      variant="outlined"
      onClick={toggleLanguage}>
      {i18n.language === "ru" ? "RU" : "EN"}
    </Button>
  );
};

export default LanguageSwitcher;
