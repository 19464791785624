import React, { createContext, useState, useContext, useEffect } from "react";
import axiosInstance from "../axiosConfig";

const AuthContext = createContext({
  user: null,
  setUser: () => {},
  role: "Guest",
  registrationComplete: false,
  loading: true,
  login: () => {},
  logout: () => {},
  register: () => {},
  verifyUser: () => {},
  completeRegistration: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("Guest");
  const [loading, setLoading] = useState(true);
  const [registrationComplete, setRegistrationComplete] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const isRegistrationComplete = localStorage.getItem("registrationComplete");
    if (token && isRegistrationComplete === "true") {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData) {
        setUser(userData);
        setRole(userData.role || "Guest");
        setRegistrationComplete(true);
      }
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      setRegistrationComplete(false);
    }
    setLoading(false);
  }, []);

  const completeRegistration = () => {
    setRegistrationComplete(true);
    localStorage.setItem("registrationComplete", "true");
  };

  const register = async (userData) => {
    try {
      console.log("AuthProvider: Attempting to register user", userData);
      const { data } = await axiosInstance.post("/api/users/register", userData);
      localStorage.setItem("jwtToken", data.token);
      localStorage.setItem("user", JSON.stringify(data));
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      setUser(data);
      setRole(data.role || "Guest");
      console.log("AuthProvider: User registered successfully", data);
    } catch (error) {
      console.error("AuthProvider: Registration error", error);
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      console.log("AuthProvider: Attempting to log in with email:", email);
      const { data } = await axiosInstance.post("/api/users/login", { email, password });
      localStorage.setItem("jwtToken", data.token);
      localStorage.setItem("user", JSON.stringify(data));
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      setUser(data);
      setRole(data.role || "Guest");
      setRegistrationComplete(true);
      localStorage.setItem("registrationComplete", "true");
      console.log("AuthProvider: Login successful", data);
    } catch (error) {
      console.error("AuthProvider: Login error", error);
      throw error;
    }
  };

  const verifyUser = async (verificationData) => {
    try {
      const { data } = await axiosInstance.put("/api/users/verify", verificationData);
      const updatedUser = { ...user, isVerified: data.isVerified };
      setUser(updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    console.log("AuthProvider: Logging out");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("user");
    localStorage.removeItem("personalInfoData");
    axiosInstance.defaults.headers.common["Authorization"] = null;
    setRegistrationComplete(false);
    setUser(null);
    setRole("Guest");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        registrationComplete,
        loading,
        login,
        setUser,
        logout,
        register,
        verifyUser,
        completeRegistration,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
