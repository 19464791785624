const categories = [
  { key: "iphones", label: "Iphone" },
  { key: "smartphones", label: "Смартфоны" },
  { key: "laptops", label: "Ноутбуки" },
  { key: "audio", label: "Аудиотехника" },
  { key: "homeAppliances", label: "Бытовая техника" },
  { key: "kitchenAppliances", label: "Техника для кухни" },
  { key: "technology", label: "Технологии" },
  { key: "art", label: "Искусство" },
  { key: "jewelry", label: "Ювелирные изделия" },
  { key: "photo_video", label: "Фото и видео" },
];

const lotStatus = [
  { key: "active", label: "Активные" },
  { key: "inactive", label: "Планируемые" },
  { key: "completed", label: "Недавно завершенные" },
];

export { categories, lotStatus };
