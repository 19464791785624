import React, { useState } from "react";
import { Box, IconButton, TextField, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useSearch } from "../../Context/SearchContext";

const SearchButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { searchTerm, handleSearch } = useSearch();
  const location = useLocation();
  const isLotsPage = location.pathname.includes("/lots");

  const handleSearchClick = () => {
    if (isLotsPage) {
      setIsExpanded(true);
    }
  };

  const handleBlur = () => {
    if (searchTerm === "") {
      setIsExpanded(false);
    }
  };

  const handleChange = (event) => {
    handleSearch(event.target.value);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", position: "relative", mr: 1 }}>
      {isExpanded ? (
        <TextField
          autoFocus
          onBlur={handleBlur}
          value={searchTerm}
          onChange={handleChange}
          variant="outlined"
          size="small"
          placeholder="Поиск"
          sx={{
            transition: "width 0.3s ease",
            maxWidth: 140,
            width: isExpanded ? "100%" : "40px",
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              borderColor: "#3083FF",
              borderWidth: "1px",
            },
            "& .MuiInputBase-input": {
              padding: "4px 6px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <IconButton
          sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2 }}
          onClick={handleSearchClick}>
          <SearchIcon sx={{ fontSize: "16px" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default SearchButton;
