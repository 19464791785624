import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Divider,
  Pagination,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@mui/material";
import { styled } from "@mui/system";
import Visa from "../../assets/images/Old_Visa_Logo 1.svg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTranslation } from "react-i18next";
import { WalletService } from "../../services/WalletService";
import InputMask from "react-input-mask";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 7;

const Balance = () => {
  const [page, setPage] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState("");
  const [isAcquiringModalOpen, setAcquiringModalOpen] = useState(false);
  const [isAmountModalOpen, setAmountModalOpen] = useState(false);
  const [newCard, setNewCard] = useState({ number: "", expiryDate: "", cvv: "", saveCard: true });
  const [cardCryptogramPacket, setCardCryptogramPacket] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const { t } = useTranslation();

  const publicId = "pk_c22015a3d770eaeeb0ff833f3e968";

  useEffect(() => {
    const fetchBalanceAndMethods = async () => {
      try {
        const fetchedBalance = await WalletService.getWalletBalance();
        const fetchedMethods = await WalletService.getPaymentMethods();
        const fetchedTransactions = await WalletService.getWalletTransactions();

        const sortedTransactions = fetchedTransactions.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
        );

        setBalance(fetchedBalance);
        setPaymentMethods(fetchedMethods);
        setTransactions(sortedTransactions);

        // Если есть способы оплаты, выбираем первый по умолчанию
        if (fetchedMethods.length > 0 && !selectedPaymentMethod) {
          setSelectedPaymentMethod(fetchedMethods[0]._id);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchBalanceAndMethods();

    const intervalId = setInterval(fetchBalanceAndMethods, 10000);

    return () => clearInterval(intervalId);
  }, [selectedPaymentMethod]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCloseAcquiringModal = () => {
    setAcquiringModalOpen(false);
    setNewCard({ number: "", expiryDate: "", cvv: "", saveCard: false });
  };

  const handleSelectPaymentMethod = (methodId) => {
    if (!methodId || methodId === "undefined") {
      console.error("Attempted to select method, but method ID was undefined or missing");
      return;
    }

    setSelectedPaymentMethod(methodId);
    console.log("Selected payment method ID:", methodId);
  };

  const handleDeletePaymentMethod = async (id) => {
    try {
      if (!id) {
        console.error("Attempted to delete payment method, but ID was undefined.");
        return;
      }

      console.log(`Attempting to delete payment method with ID: ${id}`);
      await WalletService.deletePaymentMethod(id);

      const updatedMethods = await WalletService.getPaymentMethods();
      setPaymentMethods(updatedMethods);

      if (selectedPaymentMethod === id) {
        setSelectedPaymentMethod(updatedMethods.length > 0 ? updatedMethods[0]._id : null);
      }

      console.log(`Successfully deleted payment method with ID: ${id}`);
    } catch (error) {
      console.error("Failed to delete payment method:", error);
    }
  };

  const handleCloseAmountModal = () => {
    setAmountModalOpen(false);
  };

  const handleSavePaymentMethod = async () => {
    try {
      const checkout = new window.tiptop.Checkout({
        publicId: publicId,
      });

      const fieldValues = {
        cardNumber: newCard.number.replace(/\s+/g, ""),
        expDateMonth: newCard.expiryDate.split("/")[0],
        expDateYear: newCard.expiryDate.split("/")[1],
        cvv: newCard.cvv,
      };

      const cryptogram = await checkout.createPaymentCryptogram(fieldValues);
      console.log("Generated Cryptogram:", cryptogram);

      setCardCryptogramPacket(cryptogram);

      const updatedMethods = await WalletService.addPaymentMethod({
        number: `**** **** **** ${newCard.number.slice(-4)}`,
        cryptogram: cryptogram,
      });

      setPaymentMethods(updatedMethods);
      setSelectedPaymentMethod(updatedMethods[0]._id);

      handleCloseAcquiringModal();
    } catch (error) {
      console.error("Error generating cryptogram or saving method:", error);
    }
  };

  const handleOpenAmountModal = () => {
    console.log("Current selected payment method:", selectedPaymentMethod);
    if (!selectedPaymentMethod) {
      alert("Please select a payment method.");
      return;
    }
    setAmountModalOpen(true);
  };

  const handleAddPaymentMethod = () => {
    setAcquiringModalOpen(true);
  };

  const handleConfirmAmount = async () => {
    console.log("Confirming amount. Selected payment method ID:", selectedPaymentMethod);

    const selectedMethod = paymentMethods.find((method) => method._id === selectedPaymentMethod);

    if (!selectedMethod || !selectedMethod.cryptogram) {
      console.error("Selected payment method or its cryptogram is undefined.");
      alert("Selected payment method or its cryptogram is invalid. Please try again.");
      return;
    }

    console.log("Selected payment method for confirming:", selectedMethod);

    try {
      const updatedBalance = await WalletService.replenishWallet(
        selectedMethod.cryptogram,
        parseFloat(amount),
      );
      setBalance(updatedBalance);
      handleCloseAmountModal();
    } catch (error) {
      console.error("Failed to replenish balance:", error);
    }
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedTransactions = transactions.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
        {t("balance.title")}
      </Typography>
      <Typography variant="h5" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
        {balance} {t("currency")}
      </Typography>
      <Button
        variant="contained"
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "6px 24px",
          fontSize: "14px",
          fontWeight: 400,
          textTransform: "none",
          mb: 4,
        }}
        onClick={handleOpenAmountModal}
        disabled={paymentMethods.length === 0}>
        {t("balance.replenishBalance")}
      </Button>

      <Typography variant="h6" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
        {t("balance.paymentMethods")}
      </Typography>
      {paymentMethods.map((method, index) => {
        return (
          <Box
            key={method._id || index}
            sx={{ display: "flex", alignItems: "center", mb: 3, p: 1 }}>
            <Radio
              checked={selectedPaymentMethod === method._id}
              onChange={() => handleSelectPaymentMethod(method._id)}
              value={method._id}
              name="paymentMethod"
            />
            <img src={Visa} alt="Visa" style={{ marginRight: 10 }} />
            <Typography>
              {t("balance.card")} {method.cardNumber}
            </Typography>
            <IconButton onClick={() => handleDeletePaymentMethod(method._id)}>
              <DeleteOutlinedIcon sx={{ color: "#89939E" }} />
            </IconButton>
          </Box>
        );
      })}

      <Button
        variant="contained"
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "6px 24px",
          fontSize: "14px",
          fontWeight: 400,
          textTransform: "none",
          mb: 4,
        }}
        onClick={handleAddPaymentMethod}>
        {t("balance.add")}
      </Button>
      <Divider sx={{ mb: 3, borderColor: "#717171" }} />

      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        {t("balance.operationHistory")}
      </Typography>
      {paginatedTransactions.length === 0 ? (
        <Typography sx={{ fontSize: "16px" }}>Нет истории</Typography>
      ) : (
        paginatedTransactions.map((transaction, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              py: 1.5,
              backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA",
            }}>
            <Typography
              sx={{ minWidth: "120px", color: "#717171", fontSize: "14px", fontWeight: 600 }}>
              {new Date(transaction.timestamp).toLocaleDateString()}
            </Typography>
            <Typography
              sx={{ minWidth: "120px", color: "#717171", fontSize: "14px", fontWeight: 600 }}>
              {transaction.type === "credit" ? "+" : "-"}
              {transaction.amount} {t("currency")}
            </Typography>
            <Typography sx={{ minWidth: "120px", color: "#717171", fontSize: "14px" }}>
              {transaction.type === "credit" ? t("balance.replenishFromCard") : "вывод"}
            </Typography>
          </Box>
        ))
      )}
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(transactions.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>

      <Dialog
        open={isAcquiringModalOpen}
        onClose={handleCloseAcquiringModal}
        aria-labelledby="modal-title"
        PaperProps={{
          sx: {
            px: 3,
            borderRadius: 3,
            textAlign: "center",
            minWidth: 500,
            overflow: "hidden",
          },
        }}>
        <DialogTitle id="modal-title">Enter Card Details</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            {/* Input mask for card number */}
            <InputMask
              mask="9999 9999 9999 9999" // 16 digits, grouped by 4
              value={newCard.number}
              onChange={(e) => setNewCard({ ...newCard, number: e.target.value })}>
              {() => (
                <TextField
                  label="Card Number"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                />
              )}
            </InputMask>

            <Box sx={{ display: "flex", gap: 3, mt: 3 }}>
              {/* Input mask for expiration date */}
              <InputMask
                mask="99/99" // MM/YY format
                value={newCard.expiryDate}
                onChange={(e) => setNewCard({ ...newCard, expiryDate: e.target.value })}>
                {() => (
                  <TextField
                    label="Expiration Date (MM/YY)"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                  />
                )}
              </InputMask>

              {/* Input mask for CVV */}
              <InputMask
                mask="999" // 3 digits for CVV
                value={newCard.cvv}
                onChange={(e) => setNewCard({ ...newCard, cvv: e.target.value })}>
                {() => (
                  <TextField
                    label="CVV"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                  />
                )}
              </InputMask>
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  checked={newCard.saveCard}
                  onChange={(e) => setNewCard({ ...newCard, saveCard: e.target.checked })}
                />
              }
              label="Save Card for Future Payments"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontWeight: 400,
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            gap: 1,
            "& > :not(style) ~ :not(style)": {
              marginLeft: "0 !important",
            },
          }}>
          <Button
            variant="contained"
            onClick={handleSavePaymentMethod}
            sx={{
              width: "92%",
              borderRadius: 2,
              py: 1.5,
              background: "#026E8C",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
            }}>
            Save and Continue
          </Button>
          <Button
            variant="text"
            onClick={handleCloseAcquiringModal}
            sx={{
              width: "100%",
              borderRadius: 2,
              pt: 1,
              mb: 3,
              color: "#00796b",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
              textDecoration: "underline",
            }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isAmountModalOpen}
        onClose={handleCloseAmountModal}
        aria-labelledby="amount-modal-title"
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 3,
            textAlign: "center",
            minWidth: 400,
          },
        }}>
        <DialogTitle id="amount-modal-title">{t("amountModal.title")}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              variant="outlined"
              fullWidth
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            gap: 1,
          }}>
          <Button
            variant="contained"
            onClick={handleConfirmAmount}
            sx={{
              width: "100%",
              borderRadius: 2,
              py: 1,
              background: "linear-gradient(to right, #00c6ff, #0072ff)",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
            }}>
            {t("amountModal.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Balance;
