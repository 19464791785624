import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  IconButton,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useFavorite } from "../../Context/FavoriteContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 12;

const Favorites = () => {
  const { favorites, removeFavorite } = useFavorite();
  const [page, setPage] = React.useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = (item) => {
    removeFavorite(item);
  };

  const handleParticipateClick = (itemId) => {
    navigate(`/lots/${itemId}`);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedCards = favorites.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        {t("favorites.title")}
      </Typography>
      <Grid container spacing={2}>
        {paginatedCards.map((item, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                p: 2,
                boxShadow: "none",
                border: "1px solid #ABBED1",
                borderRadius: "0px",
                height: "100%",
              }}>
              <CardMedia
                component="img"
                sx={{ width: { xs: "100%", sm: 100 }, height: "100%", objectFit: "cover" }}
                image={item.image ? `${process.env.REACT_APP_BACKEND_BASE_URL}${item.image}` : "No image available"}
                alt={item.name}
              />
              <CardContent
                sx={{
                  flex: "1 0 auto",
                  py: { xs: 1, sm: 0 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                <Box>
                  <Typography fontSize={"20px"} fontWeight={600} component="div" variant="h5">
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle1" fontSize={"14px"} my={1} component="div">
                    {t("favorites.startDate")}: {new Date(item.startDate).toLocaleDateString()}
                  </Typography>
                </Box>
                {item.status !== "inactive" && (
                  <Button
                    variant="contained"
                    sx={{
                      background:
                        "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "6px 24px",
                      fontSize: "14px",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                    onClick={() => handleParticipateClick(item._id)}>
                    {t("favorites.participate")}
                  </Button>
                )}
              </CardContent>
              <Box sx={{ display: "flex", alignItems: "flex-start", pl: 1 }}>
                <IconButton
                  sx={{ pt: 0, pb: { xs: 0, sm: 3 } }}
                  aria-label="delete"
                  onClick={() => handleDelete(item)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(favorites.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default Favorites;
