import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, Grid } from "@mui/material";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import { emailRegex, phoneRegex } from "../../utils/regex";
import { useAuth } from "../../Context/AuthContext";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const PersonalData = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [verificationRequested, setVerificationRequested] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/api/users/profile");
        console.log("Fetched user data: ", response.data);

        setUserData(response.data);

        const verificationStatus = response.data.verificationRequest || false;
        setVerificationRequested(verificationStatus);
        console.log("Verification requested state after fetch: ", verificationStatus);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleVerificationClick = () => {
    const personalData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      dob: userData.dob,
      country: userData.country,
      city: userData.city,
      street: userData.street,
      houseNumber: userData.houseNumber,
      apartment: userData.apartment,
    };

    localStorage.setItem("personalInfoData", JSON.stringify(personalData));
    console.log("Redirecting to verification page with data:", personalData);

    navigate("/register/verification", { state: personalData });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const validateFields = () => {
    let tempErrors = {};

    if (!userData.phone || !phoneRegex.test(userData.phone)) {
      tempErrors.phone = "Invalid phone number";
    }

    if (userData.email && !emailRegex.test(userData.email)) {
      tempErrors.email = "Invalid email address";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateFields()) {
      try {
        const { _id, ...updatedData } = userData;
        const response = await axiosInstance.put(`/api/users/profile/${_id}`, updatedData);
        console.log("Data saved successfully");

        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));

        alert("Данные успешно сохранены");
      } catch (error) {
        console.error("Ошибка при сохранении данных пользователя:", error);
      }
    }
  };

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
        {t("personalData.title")}
      </Typography>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        {t("personalData.verification")}
      </Typography>
      {userData.isVerified ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            backgroundColor: "#e3f2fd",
            padding: 1,
            pr: 2,
            borderRadius: "10px",
            mb: 3,
            maxWidth: "fit-content",
          }}>
          <GppGoodOutlinedIcon sx={{ color: "#3083FF" }} />
          <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}>
            {t("personalData.verifiedUser")}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              backgroundColor: "#FEEADD",
              padding: 1,
              pr: 2,
              borderRadius: "10px",
              maxWidth: "fit-content",
            }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
              {verificationRequested ? "Ожидание подтверждения" : "Не верифицирован"}
            </Typography>
          </Box>
          {!verificationRequested && (
            <Typography
              sx={{ color: "#0671E0", fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
              onClick={handleVerificationClick}>
              Пройти верификацию
            </Typography>
          )}
        </Box>
      )}

      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        {t("personalData.personalInfo")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.email")}
          </Typography>
          <TextField
            value={userData.email || ""}
            name="email"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            InputProps={{ readOnly: true }}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.phone")}
          </Typography>
          <TextField
            value={userData.phone || ""}
            name="phone"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.lastName")}
          </Typography>
          <TextField
            value={userData.lastName || ""}
            name="lastName"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.firstName")}
          </Typography>
          <TextField
            value={userData.firstName || ""}
            name="firstName"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.middleName")}
          </Typography>
          <TextField
            value={userData.middleName || ""}
            name="middleName"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.dob")}
          </Typography>
          <TextField
            value={userData.dob || ""}
            name="dob"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        {t("personalData.password")}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          borderColor: "#3083FF",
          color: "#3083FF",
          p: "12px 34px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
          borderRadius: "10px",
          mb: 2,
        }}>
        {t("personalData.changePassword")}
      </Button>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        {t("personalData.address")}
      </Typography>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
          {t("personalData.postalCode")}
        </Typography>
        <TextField
          value={userData.postalCode || ""}
          name="postalCode"
          variant="outlined"
          fullWidth
          sx={textFieldStyles}
          onChange={handleChange}
        />
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.country")}
          </Typography>
          <TextField
            value={userData.country || ""}
            name="country"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.region")}
          </Typography>
          <TextField
            value={userData.region || ""}
            name="region"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.city")}
          </Typography>
          <TextField
            value={userData.city || ""}
            name="city"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.street")}
          </Typography>
          <TextField
            value={userData.street || ""}
            name="street"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.houseNumber")}
          </Typography>
          <TextField
            value={userData.houseNumber || ""}
            name="houseNumber"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            {t("personalData.apartment")}
          </Typography>
          <TextField
            value={userData.apartment || ""}
            name="apartment"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={handleSave}
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "12px 32px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
        }}>
        {t("personalData.save")}
      </Button>
    </Box>
  );
};

export default PersonalData;
