import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuctionItemService } from "../../services/AuctionItemService";

const Carousel = () => {
  const { t } = useTranslation();
  const sliderRef = useRef();
  const navigate = useNavigate();
  const [activeAuctions, setActiveAuctions] = useState([]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchActiveAuctions = async () => {
      try {
        const allAuctions = await AuctionItemService.fetchAuctionItems();
        const activeAuctions = allAuctions.filter((auction) => auction.status === "active");

        setActiveAuctions(activeAuctions.slice(0, 20));
      } catch (error) {
        console.error("Error fetching active auctions:", error);
      }
    };

    fetchActiveAuctions();
  }, []);

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleParticipateClick = (auctionId) => {
    navigate(`/lots/${auctionId}`);
  };

  return (
    <div style={{ position: "relative" }}>
      <Slider ref={sliderRef} {...settings}>
        {activeAuctions.length > 0 ? (
          activeAuctions.map((auction) => (
            <div key={auction._id}>
              <Grid
                container
                className="banner"
                sx={{
                  mt: { lg: 5, xs: 2 },
                  height: { lg: "418px", xs: "34vw" },
                  backgroundImage: `url(${
                    auction.sliderImage
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}${auction.sliderImage}`
                      : `${process.env.REACT_APP_BACKEND_BASE_URL}${auction.image}`
                  })`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  position: "relative",
                }}>
                <Grid item xs={5}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: { lg: 2, xs: 1 } }}>
                    <Typography
                      className="title"
                      variant="h3"
                      component="div"
                      sx={{
                        ml: "25%",
                        color: "white",
                        fontSize: { lg: "42px", xs: "3.2vw" },
                        fontWeight: 700,
                        wordWrap: "break-word",
                      }}>
                      {auction.name}
                    </Typography>
                  </Box>
                  <Box sx={{ ml: "25%", display: "flex", alignItems: "center", color: "white" }}>
                    <Typography
                      sx={{ fontWeight: 400, mr: "10px", fontSize: { lg: "20px", xs: "1.6vw" } }}
                      variant="subtitle1">
                      {t("carousel.start")}
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: { lg: "20px", xs: "1.6vw" } }}>
                      {new Date(auction.startDate).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box sx={{ ml: "25%", display: "flex", alignItems: "center", color: "white" }}>
                    <Typography
                      sx={{ fontWeight: 400, mr: "10px", fontSize: { lg: "20px", xs: "1.6vw" } }}
                      variant="subtitle1">
                      {t("carousel.startPrice")}
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: { lg: "20px", xs: "1.6vw" } }}>
                      {`${auction.startingPrice} тг`}
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      mt: { lg: 3, xs: 2 },
                      ml: "25%",
                      backgroundColor: "white",
                      fontWeight: 400,
                      borderRadius: 2,
                      fontSize: { lg: "16px", xs: "1.1vw" },
                    }}
                    variant="solid"
                    onClick={() => handleParticipateClick(auction._id)}>
                    {t("carousel.participate")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          ))
        ) : (
          <Typography variant="h5" align="center" sx={{ mt: 3 }}>
            {t("carousel.noActiveAuctions")}
          </Typography>
        )}
      </Slider>
      <IconButton
        className="slick-prev"
        sx={{
          position: "absolute",
          left: "-20px",
          top: "55%",
          transform: "translateY(-50%)",
          color: "black",
          backgroundColor: "white",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          width: 40,
          height: 40,
          "&:hover": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
          "&:focus": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
        }}
        onClick={prevSlide}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        className="slick-next"
        sx={{
          position: "absolute",
          right: "-20px",
          top: "55%",
          transform: "translateY(-50%)",
          color: "black",
          backgroundColor: "white",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          width: 40,
          height: 40,
          "&:hover": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
          "&:focus": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
        }}
        aria-label="next"
        onClick={nextSlide}>
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

export default Carousel;
