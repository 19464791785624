import axiosInstance from "../axiosConfig";

export interface User {
  _id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  city?: string;
  bids?: number;
  bidAmount?: number;
}

export class UserService {
  static async fetchUsers(): Promise<User[]> {
    try {
      const response = await axiosInstance.get<User[]>("/api/users");
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }

  public async getUserId(): Promise<string | undefined> {
    try {
      const response = await axiosInstance.get("/api/users/profile");
      return response?.data?._id;
    } catch (error) {
      console.error("Error fetching user ID:", error);
      throw error;
    }
  }
}
