import axiosInstance from "../axiosConfig";

export interface Wallet {
  balance: number;
  transactions: Transaction[];
}

export interface Transaction {
  amount: number;
  type: "credit" | "debit";
  timestamp: Date;
}

export interface PaymentMethod {
  _id: string;
  number: string;
  cryptogram: string;
}

export class WalletService {
  // Получение баланса кошелька пользователя
  static async getWalletBalance(): Promise<number> {
    try {
      const response = await axiosInstance.get<{ balance: number }>("/api/wallets/balance");
      return response.data.balance;
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      throw error;
    }
  }

  // Пополнение кошелька пользователя на определённую сумму
  static async replenishWallet(cardCryptogramPacket: string, amount: number): Promise<number> {
    const paymentData = {
      cardCryptogramPacket,
      Amount: amount,
      InvoiceId: "1234567",
      AccountId: "user_x",
      Description: "Пополнение баланса",
    };

    try {
      const response = await axiosInstance.post<{ balance: number }>(
        "/api/wallets/replenish",
        paymentData,
      );
      return response.data.balance;
    } catch (error: any) {
      console.error("Error replenishing wallet:", error);
      throw error;
    }
  }

  // Получение сводки транзакций за указанный период
  static async getWalletSummary(
    period: "24_hours" | "week" | "month" | "year" | "all_time",
  ): Promise<{ totalCredit: number; totalDebit: number; totalBalance: number }> {
    try {
      const response = await axiosInstance.get<{
        totalCredit: number;
        totalDebit: number;
        totalBalance: number;
      }>(`/api/wallets/summary?period=${period}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching wallet summary:", error);
      throw error;
    }
  }

  static async addPaymentMethod(paymentMethod: PaymentMethod): Promise<PaymentMethod[]> {
    try {
      const response = await axiosInstance.post<PaymentMethod[]>(
        "/api/wallets/save-payment-method",
        {
          cardNumber: paymentMethod.number,
          cryptogram: paymentMethod.cryptogram,
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error adding payment method:", error);
      throw error;
    }
  }

  static async deletePaymentMethod(id: string): Promise<void> {
    try {
      await axiosInstance.delete(`/api/wallets/payment-method/${id}`);
    } catch (error) {
      console.error("Error deleting payment method:", error);
      throw error;
    }
  }

  static async getPaymentMethods(): Promise<PaymentMethod[]> {
    try {
      const response = await axiosInstance.get<PaymentMethod[]>("/api/wallets/payment-methods");
      return response.data;
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      throw error;
    }
  }

  // Списание средств за участие в аукционе
  static async deductParticipationFee(amount: number): Promise<void> {
    try {
      const response = await axiosInstance.post("/api/wallets/deduct", { amount });
      console.log("Participation fee deducted successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error deducting participation fee:", error);
      throw error;
    }
  }

  static async joinAuction(auctionItemId: string): Promise<void> {
    try {
      console.log(`Sending request to join auction with auctionItemId: ${auctionItemId}`);
      const response = await axiosInstance.post("/api/wallets/join-auction", { auctionItemId });
      console.log("Successfully joined the auction", response.data);
    } catch (error) {
      console.error("Error joining auction:", error);
      throw error;
    }
  }

  static async getWalletTransactions(): Promise<Transaction[]> {
    try {
      const response = await axiosInstance.get<Transaction[]>("/api/wallets/transactions");
      return response.data;
    } catch (error) {
      console.error("Error fetching wallet transactions:", error);
      throw error;
    }
  }
}
