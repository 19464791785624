import React, { useEffect, useState } from "react";
import { Grid, Box, Pagination, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuctionItemService } from "../../services/AuctionItemService";
import CardItem from "../CardItem/CardItem";

const ITEMS_PER_PAGE = 12;

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const LotGrid = ({ searchTerm, selectedCategories, selectedStatus }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filteredCards, setFilteredCards] = useState([]);
  const [allCards, setAllCards] = useState([]);

  useEffect(() => {
    const fetchAuctionItems = async () => {
      try {
        const items = await AuctionItemService.fetchAuctionItems();
        setAllCards(items);
        setFilteredCards(items);
      } catch (error) {
        console.error("Error fetching auction items:", error);
      }
    };

    fetchAuctionItems();
  }, []);

  useEffect(() => {
    let filteredData = allCards;

    if (searchTerm) {
      const lowercasedFilter = searchTerm.toLowerCase();
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(lowercasedFilter),
      );
    }

    if (selectedCategories.length > 0) {
      filteredData = filteredData.filter((item) => selectedCategories.includes(item.category));
    }

    if (selectedStatus.length > 0) {
      filteredData = filteredData.filter((item) => selectedStatus.includes(item.status));
    }

    setFilteredCards(filteredData);
  }, [searchTerm, selectedCategories, selectedStatus, allCards]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedCards = filteredCards.slice(startIndex, endIndex);

  const getCardType = (status) => {
    switch (status) {
      case "inactive":
        return "nearest";
      case "active":
        return "active";
      case "completed":
        return "completed";
      default:
        return "buyNow";
    }
  };

  return (
    <Box sx={{ mb: 6 }}>
      <Grid container spacing={2}>
        {paginatedCards.map((item) => (
          <Grid item xs={12} sm={6} lg={4} key={item._id}>
            <CardItem item={item} type={getCardType(item.status)} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(filteredCards.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default LotGrid;
