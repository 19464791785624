import React, { useState, useEffect } from "react";
import { Grid, Drawer, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Sidebar from "../../components/Sidebar/Sidebar";
import LotGrid from "../../components/LotGrid/LotGrid";
import LotsMobile from "../../components/LotsMobile/LotsMobile";
import { useSearch } from "../../Context/SearchContext";
import { useLocation } from "react-router-dom";

export default function Lots() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const { searchTerm } = useSearch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");

    if (category) {
      setSelectedCategories([category]);
    }
  }, [location]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((item) => item !== category)
        : [...prevSelected, category],
    );
  };

  const handleStatusChange = (status) => {
    setSelectedStatus((prevSelected) =>
      prevSelected.includes(status)
        ? prevSelected.filter((item) => item !== status)
        : [...prevSelected, status],
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}></Box>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        {!isMobile && (
          <Sidebar
            onCategoryChange={handleCategoryChange}
            onStatusChange={handleStatusChange}
            selectedCategories={selectedCategories}
            selectedStatus={selectedStatus}
          />
        )}
        <Grid item xs={12} md={9}>
          <LotsMobile isMobile={isMobile} toggleDrawer={toggleDrawer} />
          <LotGrid
            searchTerm={searchTerm}
            selectedCategories={selectedCategories}
            selectedStatus={selectedStatus}
          />
        </Grid>
      </Grid>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Sidebar
          onCategoryChange={handleCategoryChange}
          onStatusChange={handleStatusChange}
          selectedCategories={selectedCategories}
          selectedStatus={selectedStatus}
        />
      </Drawer>
    </>
  );
}
