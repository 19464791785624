import React, { useState } from "react";
import { AppBar, Box, Toolbar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

const AdminHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login"); // Перенаправление на страницу входа после выхода
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      <AppBar position="static" sx={{ backgroundColor: "#FFF", boxShadow: "none", py: 0.5 }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              color: "black",
              fontSize: "39px",
              fontWeight: 700,
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}>
            brama.kz
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={handleMenuClick}
              sx={{
                color: "black",
                fontSize: "20px",
                fontWeight: 500,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}>
              {user ? user.firstName : "User"}{" "}
              <ArrowDropDownIcon sx={{ ml: 1, color: "#8E8E93" }} />
            </Typography>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && open}  // Добавляем проверку на существование 
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  borderRadius: 2,
                  minWidth: 165,
                },
              }}>
              <MenuItem onClick={handleMenuClose}>Профиль</MenuItem>
              <MenuItem onClick={handleLogout}>Выйти</MenuItem>
            </Menu>
            <IconButton onClick={handleLogout} sx={{ color: "black", ml: 2 }}>
              <ExitToAppIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AdminHeader;
