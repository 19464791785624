import React, { useMemo, useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RouterBuilder from "./App.router";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import axiosInstance from "./axiosConfig";
import { AuthProvider } from "./Context/AuthContext";
import { NotificationProvider } from "./Context/NotificationContext";
import { SocketProvider } from "./Context/SocketContext";

const App = () => {
  const [serverData, setServerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("App component mounted. Fetching data from server...");
    axiosInstance
      .get("/")
      .then((response) => {
        console.log("Data received from server:", response.data);
        setServerData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data from server:", err);
        setError(err);
        setLoading(false);
      });
  }, []);

  const routes = useMemo(() => {
    console.log("Building routes using RouterBuilder...");
    return RouterBuilder();
  }, []);

  console.log("Rendering App component...");

  return (
    <SocketProvider>
      <AuthProvider>
        <NotificationProvider>
          <RouterProvider router={createBrowserRouter(routes)}>
            <ScrollToTop />
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {serverData && (
              <div>
                <h1>Data from server:</h1>
                <pre>{JSON.stringify(serverData, null, 2)}</pre>
              </div>
            )}
          </RouterProvider>
        </NotificationProvider>
      </AuthProvider>
    </SocketProvider>
  );
};

export default App;
