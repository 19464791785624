import React from "react";
import { Box, IconButton } from "@mui/material";
import { PersonOutlined as PersonOutlinedIcon } from "@mui/icons-material";

const UnauthorizedMobile = ({ onUserIconClick }) => {
  return (
    <Box sx={{ mt: 3 }}>
      <IconButton
        onClick={onUserIconClick}
        sx={{
          ml: 3,
          color: "#3083FF",
          border: "1px solid #3083FF",
          borderRadius: 2,
          padding: "8px",
        }}>
        <PersonOutlinedIcon sx={{ fontSize: "24px" }} />
      </IconButton>
    </Box>
  );
};

export default UnauthorizedMobile;
