import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import axiosInstance from "../../axiosConfig";
import { useNavigate } from "react-router-dom";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const UsersAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState({
    id: "",
    email: "",
    name: "",
    phone: "",
    isVerified: "",
    verificationRequest: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      console.log("Fetching users from API");
      try {
        const response = await axiosInstance.get("/api/users");
        console.log("Response data: ", response.data);
        setUsers(response.data);
      } catch (error) {
        console.error("Ошибка при получении данных пользователей: ", error);
      }
    };
    fetchUsers();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleRowClick = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  const filteredUsers = users.filter((user) => {
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    return (
      (!search.id || user._id.toString().toLowerCase().includes(search.id.toLowerCase())) &&
      (!search.email || user.email.toLowerCase().includes(search.email.toLowerCase())) &&
      (!search.name || fullName.includes(search.name.toLowerCase())) &&
      (!search.phone || user.phone.toLowerCase().includes(search.phone.toLowerCase())) &&
      (!search.isVerified ||
        (user.isVerified ? "да" : "нет").includes(search.isVerified.toLowerCase())) &&
      (!search.verificationRequest ||
        (user.verificationRequest ? "да" : "нет").includes(
          search.verificationRequest.toLowerCase(),
        ))
    );
  });

  console.log("Filtered users: ", filteredUsers);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  console.log("Paginated users: ", paginatedUsers);

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
        Пользователи
      </Typography>
      <Table sx={{ minWidth: 650, mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              ID
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="id"
                value={search.id}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Email
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="email"
                value={search.email}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Имя
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="name"
                value={search.name}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Телефон
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="phone"
                value={search.phone}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Верифицирован
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="isVerified"
                value={search.isVerified}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Запрос на верификацию
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="verificationRequest"
                value={search.verificationRequest}
                onChange={handleSearchChange}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedUsers.map((user) => (
            <TableRow key={user._id} onClick={() => handleRowClick(user._id)}>
              <TableCell>{user._id}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.firstName ? `${user.firstName} ${user.lastName}` : "-"}</TableCell>
              <TableCell>{user.phone}</TableCell>
              <TableCell>{user.isVerified ? "Да" : "Нет"}</TableCell>
              <TableCell>{user.verificationRequest ? "Да" : "Нет"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(filteredUsers.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

export default UsersAdmin;
