import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import OrderDetail from "./OrderDetail";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const OrdersAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState({
    lotNumber: "",
    product: "",
    date: "",
    cost: "",
    customer: "",
    status: "",
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const handleRowClick = (order) => {
    setSelectedOrder(order);
  };

  const handleBack = () => {
    setSelectedOrder(null);
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const orders = [
    {
      lotNumber: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      cost: "34 500 тг",
      customer: "Иванов Константин",
      status: "Новый",
    },
    {
      lotNumber: "135467881",
      product: "Samsung Galaxy S21",
      date: "26.08.2024 10:00",
      cost: "40 000 тг",
      customer: "Петров Александр",
      status: "Не подтвержден",
    },
    {
      lotNumber: "135467882",
      product: "MacBook Pro",
      date: "27.08.2024 11:30",
      cost: "250 000 тг",
      customer: "Сидоров Николай",
      status: "Подтвержден",
    },
    {
      lotNumber: "135467883",
      product: "Sony PlayStation 5",
      date: "28.08.2024 12:00",
      cost: "150 000 тг",
      customer: "Кузнецов Михаил",
      status: "Отправлен",
    },
    {
      lotNumber: "135467884",
      product: "Apple Watch Series 6",
      date: "29.08.2024 13:15",
      cost: "50 000 тг",
      customer: "Васильева Анна",
      status: "Завершен",
    },
    {
      lotNumber: "135467885",
      product: "Google Pixel 6",
      date: "30.08.2024 14:45",
      cost: "60 000 тг",
      customer: "Дмитриев Павел",
      status: "Новый",
    },
    {
      lotNumber: "135467886",
      product: "Dell XPS 13",
      date: "31.08.2024 15:30",
      cost: "200 000 тг",
      customer: "Кириллова Екатерина",
      status: "Не подтвержден",
    },
    {
      lotNumber: "135467887",
      product: "Xiaomi Mi 11",
      date: "01.09.2024 16:00",
      cost: "45 000 тг",
      customer: "Леонов Алексей",
      status: "Подтвержден",
    },
    {
      lotNumber: "135467888",
      product: "HP Spectre x360",
      date: "02.09.2024 17:00",
      cost: "180 000 тг",
      customer: "Морозов Виктор",
      status: "Отправлен",
    },
    {
      lotNumber: "135467889",
      product: "Nintendo Switch",
      date: "03.09.2024 18:30",
      cost: "70 000 тг",
      customer: "Трофимов Игорь",
      status: "Завершен",
    },
  ];

  const filteredOrders = orders.filter((order) =>
    Object.keys(search).every((key) =>
      order[key].toLowerCase().includes(search[key].toLowerCase()),
    ),
  );

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedOrders = filteredOrders.slice(startIndex, endIndex);

  if (selectedOrder) {
    return <OrderDetail order={selectedOrder} onBack={handleBack} />;
  }

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
        Заказы
      </Typography>
      <Table sx={{ minWidth: 650, my: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 100,
              }}>
              № Лот
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="lotNumber"
                value={search.lotNumber}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Товар
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="product"
                value={search.product}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="date"
                value={search.date}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Стоимость
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="cost"
                value={search.cost}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Покупатель
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="customer"
                value={search.customer}
                onChange={handleSearchChange}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Статус
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="status"
                value={search.status}
                onChange={handleSearchChange}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedOrders.map((order, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}
              onClick={() => handleRowClick(order)}
              style={{ cursor: "pointer" }}>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.lotNumber}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.product}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.date}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.cost}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.customer}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                <Box
                  sx={{
                    backgroundColor:
                      order.status === "Завершен"
                        ? "transparent"
                        : order.status === "В процессе"
                        ? "#FEEADD"
                        : order.status === "Отправлен"
                        ? "#00CD52"
                        : order.status === "Подтвержден"
                        ? "#D9F8E5"
                        : order.status === "Не подтвержден"
                        ? "#FEEADD"
                        : "#E3EEFF",
                    borderRadius: "8px",
                    p: "5px 10px",
                    textAlign: "center",
                    width: "fit-content",
                  }}>
                  {order.status}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(filteredOrders.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

export default OrdersAdmin;
