import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import VisaTT from "../../assets/images/visa.png";
import MCTT from "../../assets/images/mc.png";
const PaymentDelivery = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        my: 6,
      }}>
      <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
        {t("paymentDelivery.title")}
      </Typography>
      <Typography variant="h6">Платежи. Оплата банковской картой онлайн</Typography>
      <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Наш сайт подключен к интернет-эквайрингу, и Вы можете оплатить баланс банковской
              картой Visa или Mastercard. После подтверждения выбранного лота откроется защищенное
              окно с платежной страницей процессингового центра TipTop Pay, где Вам необходимо
              ввести данные Вашей банковской карты. Для дополнительной аутентификации держателя
              карты используется протокол 3-D Secure. Если Ваш Банк-эмитент поддерживает данную
              технологию, Вы будете перенаправлены на его сервер для прохождения дополнительной
              идентификации. Информацию о правилах и методах дополнительной идентификации уточняйте
              в Банке, выдавшем Вам банковскую карту.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Услуга онлайн-оплаты осуществляется в соответствии с правилами Международных платежных
              систем Visa и MasterCard на принципах соблюдения конфиденциальности и безопасности
              совершения платежа, для этого используются самые актуальные методы проверки,
              шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты
              осуществляется в защищенном окне на платежной странице TipTop Pay.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              В поля на платежной странице требуется ввести номер карты, имя владельца карты, срок
              действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard).
              Все необходимые данные отображены на поверхности банковской карты.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              CVV2/ CVC2 — это трёхзначный код безопасности, находящийся на оборотной стороне карты.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Далее в том же окне откроется страница Вашего банка-эмитента для ввода 3-D Secure
              кода. В случае, если у вас не настроен статичный 3-D Secure, он будет отправлен на ваш
              номер телефона посредством SMS. Если 3-D Secure код к Вам не пришел, то следует
              обратится в ваш банк-эмитент.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              3-D Secure — это самая современная технология обеспечения безопасности платежей по
              картам в сети интернет. Позволяет однозначно идентифицировать подлинность держателя
              карты, осуществляющего операцию, и максимально снизить риск мошеннических операций по
              карте.
              </Typography>
            <br />
            <Typography variant="h6">Гарантии безопасности</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Процессинговый центр TipTop Pay защищает и обрабатывает данные Вашей банковской карты
              по стандарту безопасности PCI DSS 3.0. Передача информации в платежный шлюз происходит
              с применением технологии шифрования SSL. Дальнейшая передача информации происходит по
              закрытым банковским сетям, имеющим наивысший уровень надежности. TipTop Pay не
              передает данные Вашей карты нам и иным третьим лицам. Для дополнительной
              аутентификации держателя карты используется протокол 3-D Secure.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              В случае, если у Вас есть вопросы по совершенному платежу, Вы можете обратиться в
              службу поддержки клиентов платежного сервиса по электронной почте
              support@cloudpayments.kz.
              </Typography>
            <br />
            <Typography variant="h6">Безопасность онлайн-платежей</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Предоставляемая Вами персональная информация (имя, адрес, телефон, e-mail, номер
              кредитной карты) является конфиденциальной и не подлежит разглашению. Данные Вашей
              кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем
              Web-сервере.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Безопасность обработки Интернет-платежей гарантирует ТОО «TipTop Pay Kazakhstan». Все
              операции с платежными картами происходят в соответствии с требованиями VISA
              International, MasterCard и других платежных систем. При передаче информации
              используются специализированные технологии безопасности карточных онлайн-платежей,
              обработка данных ведется на безопасном высокотехнологичном сервере процессинговой
              компании.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Оплата платежными картами безопасна, потому что:
              </Typography>
              <ul>
                <li>
                  Система авторизации гарантирует покупателю, что платежные реквизиты его платежной
                  карты (номер, срок действия, CVV2/CVC2) не попадут в руки мошенников, так как эти
                  данные не хранятся на сервере авторизации и не могут быть похищены.
                </li>
                <li>
                  Покупатель вводит свои платежные данные непосредственно в системе авторизации
                  TipTop Pay, а не на сайте интернет-магазина, следовательно, платежные реквизиты
                  карточки покупателя не будут доступны третьим лицам.
                </li>
              </ul>
              <br />
              <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <img src={MCTT} alt="Visa" style={{ marginRight: 10 }} />
              <img src={VisaTT} alt="MC" style={{ marginRight: 10 }} />
              </Box>
            <br />
            <Typography variant="h6">Правила доставки</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Доставка осуществляется согласно{" "}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                правилам платформы
              </a>
              </Typography>
            <br />
            <Typography variant="h6">Возврат денежных средств</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              При проведении онлайн-оплаты посредством платежных карт не допускается возврат
              наличными денежными средствами. Порядок возврата регулируется правилами международных
              платежных систем:
              </Typography>
            <ol>
              <li>
                Потребитель вправе отказаться от товара в любое время до его передачи, после
                передачи товара отказ необходимо оформить в течение 14 дней;
              </li>
              <li>
                Возврат товара надлежащего качества возможен в случае, если сохранены его товарный
                вид, потребительские свойства, а также документ, подтверждающий факт и условия
                покупки указанного товара;
              </li>
              <li>
                Потребитель не вправе отказаться от товара надлежащего качества, имеющего
                индивидуально-определенные свойства, если указанный товар может быть использован
                исключительно приобретающим его человеком;
              </li>
              <li>
                При отказе от товара со стороны потребителя продавец должен вернуть ему денежную
                сумму, уплаченную потребителем, не позднее чем через десять дней со дня предъявления
                потребителем соответствующего требования.
              </li>
            </ol>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о
              возврате денежных средств», которое высылается по требованию компанией на электронный
              адрес, и отправить его вместе с приложением копии документа, удостоверяющего личность,
              по адресу brama.kz@mail.ru{" "}
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Возврат денежных средств будет осуществлен на банковскую карту в течение 15 рабочих
              дней со дня получения «Заявление о возврате денежных средств» Компанией.
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Для возврата денежных средств по операциям, проведенным с ошибками, необходимо
              обратиться с письменным заявлением и приложением копии документа, удостоверяющего
              личность, и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление
              необходимо направить по адресу brama.kz@mail.ru
              </Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата
              денежных средств начинает исчисляться с момента получения Компанией Заявления и
              рассчитывается в рабочих днях без учета праздников/выходных дней.
              </Typography>
            <br />
            <Typography variant="h6">Случаи отказа в совершении платежа:</Typography>
              <ul>
                <li>
                  банковская карта не предназначена для совершения платежей через интернет, о чем
                  можно узнать, обратившись в Ваш Банк-эмитент;
                </li>
                <li>
                  недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств
                  на платежной карте Вы можете узнать, обратившись в банк, выпустивший банковскую
                  карту;
                </li>
                <li>данные банковской карты введены неверно;</li>
                <li>
                  истек срок действия банковской карты. Срок действия карты, как правило, указан на
                  лицевой стороне карты (это месяц и год, до которого действительна карта).
                  Подробнее о сроке действия карты Вы можете узнать, обратившись в банк-эмитент.
                </li>
              </ul>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой
              сайта, Вы можете обратиться по следующим контактам: brama.kz@mail.ru
              </Typography>
            <br />
            <Typography variant="h6">Конфиденциальность</Typography>
            <Typography variant="h7">1. Определения</Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Интернет проект https://brama.kz (далее – URL, «мы») серьезно относится к вопросу
              конфиденциальности информации своих клиентов и посетителей сайта https://brama.kz/
              (далее – «вы», «посетители сайта»). Персонифицированной мы называем информацию,
              содержащую персональные данные (например: ФИО, логин или название компании) посетителя
              сайта, а также информацию о действиях, совершаемых вами на сайте URL. (например: заказ
              посетителя сайта с его контактной информацией). Анонимными мы называем данные, которые
              невозможно однозначно идентифицировать с конкретным посетителем сайта (например:
              статистика посещаемости сайта).
              </Typography>
            <br />
            <Typography variant="h7">2. Использование информации</Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Мы используем персонифицированную информацию конкретного посетителя сайта
              исключительно для обеспечения ему качественного оказания услуг и их учета. Мы не
              раскрываем персонифицированных данных одних посетителей сайта URL другим посетителям
              сайта. Мы никогда не публикуем персонифицированную информацию в открытом доступе и не
              передаем ее третьим лицам. Исключением являются лишь ситуации, когда предоставление
              такой информации уполномоченным государственным органам предписано действующим
              законодательством Республики Казахстан. Мы публикуем и распространяем только отчеты,
              построенные на основании собранных анонимных данных. При этом отчеты не содержат
              информацию, по которой было бы возможным идентифицировать персонифицированные данные
              пользователей услуг. Мы также используем анонимные данные для внутреннего анализа,
              целью которого является развитие продуктов и услуг URL
              </Typography>
            <br />
            <Typography variant="h7">3. Ссылки</Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Сайт https://brama.kz/ может содержать ссылки на другие сайты, не имеющие отношения к
              нашей компании и принадлежащие третьим лицам. Мы не несем ответственности за точность,
              полноту и достоверность сведений, размещенных на сайтах третьих лиц, и не берем на
              себя никаких обязательств по сохранению конфиденциальности информации, оставленной
              вами на таких сайтах.
              </Typography>
            <br />
            <Typography variant="h7">4. Ограничение ответственности</Typography>
            <br />
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Мы делаем все возможное для соблюдения настоящей политики конфиденциальности, однако,
              мы не можем гарантировать сохранность информации в случае воздействия факторов
              находящихся вне нашего влияния, результатом действия которых станет раскрытие
              информации. Сайт https://brama.kz/ и вся размещенная на нем информация представлены по
              принципу "как есть” без каких-либо гарантий. Мы не несем ответственности за
              неблагоприятные последствия, а также за любые убытки, причиненные вследствие
              ограничения доступа к сайту URL или вследствие посещения сайта и использования
              размещенной на нем информации.
              </Typography>
            <br />
            <Typography variant="h7">5. Контакты</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              По вопросам, касающимся настоящей политики, просьба обращаться по адресу
              brama.kz@mail.ru
              </Typography>
            <br />
            <Typography variant="h7">Юридическое лицо</Typography>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              БИН/ИИН 710706401972 <br />
              Индивидуальный предприниматель BRAMA <br />
              Республика Казахстан, г.Актау, 4 микрорайон, дом №41, кв.54 <br />
              brama.kz@mail.ru
              </Typography>
      {/* {t("paymentDelivery.sections", { returnObjects: true }).map((section, index) => (
        <Box sx={{ my: 4 }} key={index}>
          <Typography variant="h6" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
            {section.title}
          </Typography>
          {section.content.map((text, idx) => (
            <Typography variant="body1" sx={{ fontSize: "14px" }} key={idx}>
                          {text.split('\n').map((str, i) => (
                <React.Fragment key={i}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          ))}
        </Box>
      ))} */}
    </Box>
  );
};

export default PaymentDelivery;
