import React from "react";
import { Box, IconButton } from "@mui/material";
import { Search as SearchIcon, PersonOutlined as PersonOutlinedIcon } from "@mui/icons-material";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

const Unauthorized = ({ onUserIconClick }) => {
  return (
    <Box sx={{ ml: "auto" }}>
      <IconButton
        sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
        <SearchIcon sx={{ fontSize: "16px" }} />
      </IconButton>
      <IconButton
        onClick={onUserIconClick}
        sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
        <PersonOutlinedIcon sx={{ fontSize: "16px" }} />
      </IconButton>
      <LanguageSwitcher />
    </Box>
  );
};

export default Unauthorized;
